import { Box, Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { RefObject } from 'react';

interface FileUploadSectionProps {
  file: File | null;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileClear: () => void;
  onFileClick: () => void;
  fileInputRef: RefObject<HTMLInputElement>;
}

export const MaintenanceFeeFileUpload = ({ file, onFileChange, onFileClear, onFileClick, fileInputRef }: FileUploadSectionProps) => (
  <Box
    sx={{
      border: "1px dashed #ccc",
      borderRadius: "8px",
      textAlign: "center",
      padding: "20px",
      cursor: file ? "default" : "pointer",
      width: "100%",
      backgroundColor: file ? "#E9EDFF" : "transparent",
    }}
    onClick={!file ? onFileClick : undefined}
  >
    <input
      type="file"
      accept=".xlsx"
      ref={fileInputRef}
      onChange={onFileChange}
      style={{ display: "none" }}
    />
    {file ? (
      <>
        <CloudUploadIcon sx={{ fontSize: 50, color: "#1976d2" }} />
        <Typography variant="h6" color="primary">
          Archivo subido: <strong>{file.name}</strong>
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={onFileClear}>
          Cambiar archivo
        </Button>
      </>
    ) : (
      <>
        <CloudUploadIcon sx={{ fontSize: 50, color: "#1976d2" }} />
        <Typography variant="h6" color="primary">
          Seleccionar archivo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Debe ser un archivo <b>.xlsx</b> usando nuestra plantilla de Excel
        </Typography>
      </>
    )}
  </Box>
);
