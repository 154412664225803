import { Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useGlobalStore from "../../../state/store";
import { useEffect, useState } from "react";
import { Check, ChevronRight } from "@mui/icons-material";
import MaintenanceFeeSummaryCard from "../components/maintenanceFeeImport/MaintenanceFeeSummaryCard";
import MaintenanceFeeDataTable from "../components/maintenanceFeeImport/MaintenanceFeeDataTable";
import { supabase } from "../../../supabase/client";

export default function MaintenanceImportStep2() {
  const navigate = useNavigate();

  const fileData = useGlobalStore((state) => state.fileData);
  const user = useGlobalStore((state) => state.user);
  const setFileData = useGlobalStore((state) => state.setFileData);

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editResidente, setEditResidente] = useState<string>("");
  const [editMonto, setEditMonto] = useState<string>("");
  const [editDepto, setEditDepto] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!fileData || fileData.length === 0) {
      navigate("/maintenance-fee/import/");
    } else {
      setLoading(false);
    }
  }, [fileData, navigate]);

  if (loading) {
    return (
      <Container
        maxWidth="sm"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const handleBackNavigate = () => {
    setFileData([]);
    navigate("/maintenance-fee/import/");
  };

  const saveCharges = async () => {
    try {
      const insertPromises = fileData.map((dpto) =>
        supabase
          .from("charges")
          .insert([
            {
              apartment_id: dpto.id,
              issue_date: dpto.fecha,
              due_date: dpto.fecha,
              total_amount: dpto.monto,
              status: "pending",
              last_reminder_sent: null,
              transaction_id: null,
              created_by: user.id,
              is_active: true,
              description: "Excel upload",
              charge_type: "maintenance_fee",
              building_id: user.buildings[0].id,
            },
          ])
          .select()
      );

      const results = await Promise.all(insertPromises);

      results.forEach(({ data, error }, index) => {
        if (error) {
          console.error(`Error inserting data for apartment ID ${fileData[index].id}:`, error);
        }
      });

      navigate("/maintenance-fee");
    } catch (error) {
      console.error("Failed to save charges:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#2563eb",
                color: "#fff",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              <Check />
            </Box>
            <Typography fontWeight="500">Preparar</Typography>
          </Box>
          <ChevronRight sx={{ mx: 2, color: "#d1d5db" }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#D9E2FF",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              2
            </Box>
            <Typography fontWeight="500">Verificar</Typography>
          </Box>
        </Box>

        <Button color="inherit" onClick={() => navigate("/maintenance-fee")}>
          Cancelar
        </Button>
      </Box>
      <Typography variant="h5" fontWeight="bold" color="#305CAB" sx={{ mb: 4 }}>
        Verificación de datos
      </Typography>

      <MaintenanceFeeSummaryCard fileData={fileData} />

      <MaintenanceFeeDataTable
        apartments={user.buildings[0].apartments}
        fileData={fileData}
        setFileData={setFileData}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        editResidente={editResidente}
        setEditResidente={setEditResidente}
        editMonto={editMonto}
        setEditMonto={setEditMonto}
        editDepto={editDepto}
        setEditDepto={setEditDepto}
        setError={setError}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" color="inherit" onClick={handleBackNavigate}>
          Atrás
        </Button>
        <Button variant="contained" color="primary" disabled={error} onClick={saveCharges}>
          Confirmar e importar datos
        </Button>
      </Box>
    </>
  );
}
