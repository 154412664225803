import * as XLSX from "xlsx";
import { format, isValid, parse } from "date-fns";
import { es } from "date-fns/locale";

export function getMonthNameByNumber(fechaString: string): string {
  const fecha = parse(fechaString, "yyyy/MM/dd", new Date());
  const mes = format(fecha, "MMMM", { locale: es });
  return mes.charAt(0).toUpperCase() + mes.slice(1);
}

export function getExpirationDate(fechaString: string): string {
  const [dia, mes, año] = fechaString.split("/");
  const ultimoDia = new Date(Number(año), Number(mes), 0);
  const nombreMes = ultimoDia.toLocaleString("es-ES", { month: "short" });
  return `${dia} ${nombreMes.charAt(0).toUpperCase()}${nombreMes.slice(1)}, ${año}`;
}

export function getTotalAppartments(fileData: any[]): string {
  const departamentosUnicos = new Set(fileData.map((item) => item.depto));
  return departamentosUnicos.size.toString();
}

export function getGoalToRaise(fileData: any[]): string {
  const totalMonto = fileData.reduce((total, item) => total + item.monto, 0);
  return `S/. ${totalMonto.toFixed(2)}`;
}

export const validateFile = (
  file: File
): Promise<{ depto: string; residente: string; monto: number; fecha: string }[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const jsonData: (string | number | undefined)[][] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        const [header, ...rows] = jsonData;

        const requiredColumns = [
          "Departamento",
          "Residente",
          "Monto a pagar",
          "Fecha de Vencimiento",
        ];
        if (!header || !requiredColumns.every((col) => header.includes(col))) {
          reject(new Error("El archivo no tiene el formato correcto."));
          return;
        }

        const fechaRaw = rows[0]?.[header.indexOf("Fecha de Vencimiento")];
        let globalFecha: string;

        if (typeof fechaRaw === "string") {
          const fechaTrimmed = fechaRaw.trim();

          const parsedDate = parse(fechaTrimmed, "dd/MM/yyyy", new Date());

          // Verificar si la fecha es válida
          if (isValid(parsedDate)) {
            globalFecha = format(parsedDate, "yyyy/MM/dd");
          } else {
            reject(new Error("No se encontró una fecha válida en el archivo."));
            return;
          }
        } else if (typeof fechaRaw === "number") {
          const date = XLSX.SSF.parse_date_code(fechaRaw);
          const day = String(date.d).padStart(2, "0");
          const month = String(date.m).padStart(2, "0");
          const year = String(date.y);
          globalFecha = `${year}/${month}/${day}`;
        } else {
          reject(new Error("No se encontró una fecha válida en el archivo."));
          return;
        }

        const dataToStore = rows
          .filter((row) => row.some((cell) => cell !== undefined && cell !== ""))
          .map((row) => ({
            depto: String(row[header.indexOf("Departamento")]) as string,
            residente: row[header.indexOf("Residente")] as string,
            monto: Number(row[header.indexOf("Monto a pagar")]),
            fecha: globalFecha,
          }));

        resolve(dataToStore);
      } catch (error) {
        reject(new Error("Error al procesar el archivo. Verifica su formato."));
      }
    };

    reader.onerror = () => {
      reject(new Error("Error al leer el archivo."));
    };

    reader.readAsArrayBuffer(file);
  });
};
