// src/features/maintenanceFee/components/maintenanceFeeDashboard/MaintenanceFeeRow.js
import React, { useState } from 'react';
import { Box, Button, IconButton, Typography, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../../config/routes';
import ConciliatePaymentDrawer from './ConciliatePaymentDrawer';

const MaintenanceFeeRow = ({ apartment, buildingId, onPaymentStatusUpdated }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [openConciliateDrawer, setOpenConciliateDrawer] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const {
    id,
    apartmentNumber,
    residentName,
    charges = [],
    payments = [],
    deuda = 0,
    emiCuota = 0,
    montoPagado = 0,
    porValidar = 0,
  } = apartment;

  const StatusIcon = deuda > 0 ? ErrorOutlineIcon : CheckCircleIcon;
  const statusIconColor = deuda > 0 ? '#EF4444' : '#22C55E';

  const handleHistorialClick = (e) => {
    e.stopPropagation();
    navigate(ROUTES.MAINTENANCE_FEE_DETAIL.replace(':id', id), {
      state: {
        apartment, 
      },
    });
  };

  // Para armar la tabla expandible
  const sharedGridStyles = {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr',
    gap: 2,
    px: 2,
    alignItems: 'center',
  };

  const statusLabels = {
    pending: 'Por Validar',
    verified: 'Validado',
    rejected: 'Rechazado',
  };

  const statusStyles = {
    pending: { bg: '#FEF3C7', text: '#92400E' },
    verified: { bg: '#DCFCE7', text: '#166534' },
    rejected: { bg: '#FEE2E2', text: '#991B1B' },
  };

  const handleConciliarClick = (payment) => {
    setSelectedPayment(payment);
    setOpenConciliateDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenConciliateDrawer(false);
    setSelectedPayment(null);
  };

  const handleStatusUpdated = (updatedPayment) => {
    // Se avisa al padre component que hay un cambio
    if (onPaymentStatusUpdated) {
      onPaymentStatusUpdated(updatedPayment);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '24px',
          mb: 2,
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
          overflow: 'hidden',
        }}
      >
        {/* Fila principal */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            '&:hover': {
              backgroundColor: '#F9FAFB',
            },
          }}
        >
          {/* Ícono de estado */}
          <StatusIcon sx={{ color: statusIconColor, mr: 2 }} />

          {/* Info de Depto */}
          <Box sx={{ width: '200px', mr: 4 }}>
            <Typography sx={{ fontWeight: 600, color: '#111827', fontSize: 16 }}>
              Depa - {apartmentNumber || '-'}
            </Typography>
            <Typography sx={{ color: '#6B7280', fontSize: 14, mt: 0.5 }}>
              {residentName || 'Sin residente'}
            </Typography>
          </Box>

          {/* Emi. Cuota M. */}
          <Box sx={{ width: '150px', mr: 4 }}>
            <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
              Emi. Cuota M.
            </Typography>
            <Typography
              sx={{
                color: emiCuota === 0 ? '#6B7280' : '#111827',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              S/ {emiCuota ? emiCuota.toFixed(2) : '-'}
            </Typography>
          </Box>

          {/* Monto Pagado */}
          <Box sx={{ width: '150px', mr: 4 }}>
            <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
              Monto Pagado
            </Typography>
            <Typography
              sx={{
                color: montoPagado === 0 ? '#6B7280' : '#111827',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              S/ {montoPagado ? montoPagado.toFixed(2) : '-'}
            </Typography>
          </Box>

          {/* Por Validar */}
          <Box sx={{ width: '150px', mr: 4 }}>
            <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
              Por Validar
            </Typography>
            <Typography
              sx={{
                color:
                  porValidar === 0
                    ? '#6B7280'
                    : porValidar > 0
                    ? '#F59E0B'
                    : '#111827',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              S/ {(porValidar ?? 0).toFixed(2)}
            </Typography>
          </Box>

          {/* Deuda */}
          <Box sx={{ width: '150px', mr: 4 }}>
            <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
              Deuda
            </Typography>
            <Typography
              sx={{
                color: deuda === 0 ? '#6B7280' : '#EF4444',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              S/ {(deuda ?? 0).toFixed(2)}
            </Typography>
          </Box>

          {/* Acciones */}
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', gap: 1 }}>
            <Button
              variant="contained"
              onClick={handleHistorialClick}
              sx={{
                bgcolor: '#315CAC',
                textTransform: 'none',
                fontSize: 14,
                px: 2,
                py: 0.75,
                borderRadius: '32px',
                minWidth: '100px',
                '&:hover': {
                  bgcolor: '#2A4F94',
                },
              }}
            >
              Historial
            </Button>
            <IconButton
              onClick={() => setExpanded(!expanded)}
              size="small"
              sx={{
                color: '#6B7280',
                '&:hover': {
                  bgcolor: 'rgba(107, 114, 128, 0.1)',
                },
              }}
            >
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>

        {/* Sección expandible */}
        {expanded && (
          <Box sx={{ p: 2, borderTop: '1px solid #E5E7EB' }}>
            <Box
              sx={{
                ...sharedGridStyles,
                mb: 2,
                '& > *': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              {['Concepto', 'Fecha', 'Cod. Operación', 'Monto', 'Estado', 'Acciones'].map(
                (header) => (
                  <Typography
                    key={header}
                    sx={{ color: '#6B7280', fontSize: 14, fontWeight: 600 }}
                  >
                    {header}
                  </Typography>
                )
              )}
            </Box>

            {payments.length === 0 ? (
              <Typography
                sx={{ color: '#6B7280', fontSize: 14, textAlign: 'center', py: 4 }}
              >
                No hay pagos registrados este mes.
              </Typography>
            ) : (
              payments.map((pago, idx) => (
                <Box
                  key={idx}
                  sx={{
                    ...sharedGridStyles,
                    py: 1.5,
                    borderBottom: '1px solid #E5E7EB',
                    '&:last-child': { borderBottom: 'none' },
                    '&:hover': { backgroundColor: '#F9FAFB' },
                    '& > *': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography noWrap sx={{ fontSize: 14 }}>
                    {pago.description || 'Pago de Cuota'}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {pago.paymentDate
                      ? format(new Date(pago.paymentDate), 'dd/MM/yyyy', {
                          locale: es,
                        })
                      : 'N/A'}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {pago.referenceNumber || 'N/A'}
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    S/ {(pago.amount ?? 0).toFixed(2)}
                  </Typography>
                  <Box>
                    <Chip
                      label={
                        pago.status ? statusLabels[pago.status] : statusLabels.rejected
                      }
                      sx={{
                        fontSize: 14,
                        backgroundColor:
                          statusStyles[pago.status]?.bg || statusStyles.rejected.bg,
                        color:
                          statusStyles[pago.status]?.text || statusStyles.rejected.text,
                        fontWeight: 500,
                        borderRadius: '16px',
                        width: 'fit-content',
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {pago.status === 'pending' && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: 'none',
                          fontSize: 14,
                          borderColor: '#E5E7EB',
                          color: '#6B7280',
                          borderRadius: '32px',
                          padding: '2px 12px',
                          height: 'auto',
                          minWidth: 'unset',
                          '&:hover': {
                            borderColor: '#4F46E5',
                            color: '#4F46E5',
                          },
                        }}
                        onClick={() => handleConciliarClick(pago)}
                      >
                        Conciliar
                      </Button>
                    )}
                    {pago.evidenceUrl && (
                      <IconButton
                        size="small"
                        sx={{
                          color: '#6B7280',
                          '&:hover': {
                            color: '#4F46E5',
                          },
                        }}
                        onClick={() => window.open(pago.evidenceUrl, '_blank')}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>

      {/* Drawer de conciliación */}
      {openConciliateDrawer && selectedPayment && (
        <ConciliatePaymentDrawer
          open={openConciliateDrawer}
          onClose={handleDrawerClose}
          buildingId={buildingId}
          payment={selectedPayment}
          onStatusUpdated={handleStatusUpdated}
        />
      )}
    </>
  );
};

export default MaintenanceFeeRow;
