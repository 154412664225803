import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { getRandomNumber } from "../../../../shared/utils/getRandomNumber";

const StatsItem = () => (
  <Box>
    <Skeleton width={getRandomNumber(40, 100) + "%"} height={14} />
    <Skeleton width={getRandomNumber(40, 100) + "%"} height={18} />
  </Box>
);

const MaintenanceFeeStatsSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        padding: "20px 24px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.05)",
        marginTop: "16px",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          alignItems: "center",
          gap: 2,
          marginBottom: "20px",
        }}
      >
        <StatsItem />
        <StatsItem />
        <StatsItem />
        <StatsItem />
        <StatsItem />
      </Box>
      <Box
        sx={{
          height: "8px",
          backgroundColor: "#E5E7EB",
          borderRadius: "6px",
          overflow: "hidden",
        }}
      >
        <Skeleton height={20} width="100%" />
      </Box>
    </Box>
  );
};

export default MaintenanceFeeStatsSkeleton;
