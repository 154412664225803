import { useEffect } from "react";
import {
  Container,
  Box,
  Alert,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { ArrowBack, PlayArrow, CheckCircle } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { useIncident } from "../hooks/useIncident.js";
import { ROUTES } from "../../../config/routes.js";
import IncidentDetailView from "../components/detail/IncidentDetailView.jsx";
import useUser from "../../account/hooks/useUser.js";
import IncidentDetailViewSkeleton from "../components/detail/IncidentDetailViewSkeleton.js";

const IncidentDetail = () => {
  const { id: incidentId } = useParams();
  const { user } = useUser();
  const {
    incident,
    loading,
    error,
    updateIncidentStatus,
    addComment,
    assignIncidentToWorker,
    fetchIncident,
  } = useIncident(incidentId);

  const handleUpdateStatus = async (incidentId: string, newStatus: string) => {
    try {
      await updateIncidentStatus(incidentId, newStatus);
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };

  const handleComment = async (commentText: string, mediaFiles: File[]) => {
    try {
      console.log("Submitting comment:", {
        commentText,
        mediaFiles,
        buildingId: incident.building_id,
      });

      if (!incident.building_id) {
        throw new Error("Missing building ID");
      }

      await addComment(commentText, mediaFiles);
    } catch (err) {
      console.error("Error adding comment:", err);
    }
  };

  const handleAssignToWorker = async () => {
    try {
      if (!incident.building_id) {
        throw new Error("Missing building ID");
      }
      await assignIncidentToWorker(
        incident.id,
        user.id,
        user.id,
        incident.building_id
      );
    } catch (err) {
      console.error("Error assigning incident to worker:", err);
    }
  };

  const handleMarkAsResolved = async () => {
    try {
      await updateIncidentStatus(incident.id, "resolved");
    } catch (err) {
      console.error("Error marking incident as resolved:", err);
    }
  };

  useEffect(() => {
    if (incidentId) {
      fetchIncident(incidentId);
    }
  }, [incidentId]);

  if (loading) {
    return <IncidentDetailViewSkeleton />;
  }

  if (!incident && !loading) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="info">Incident not found</Alert>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  // You had two "if (!incident && !loading)" blocks; you may remove one if unnecessary.

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        {/* Header: Responsive layout */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
          mb={3}
        >
          <Box display="flex" alignItems="center">
            <Link to={ROUTES.INCIDENTS}>
              <IconButton sx={{ mr: 2 }}>
                <ArrowBack />
              </IconButton>
            </Link>
            <Typography variant="h5">{incident.title}</Typography>
          </Box>
          {incident.status === "new" && incident.assigned_to === null && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={handleAssignToWorker}
              sx={{ mt: { xs: 2, sm: 0 } }} // Adds top margin on mobile
            >
              Resolver Incidente
            </Button>
          )}
          {incident.status === "in_progress" && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "status.resolved.main",
                color: "white",
                mt: { xs: 2, sm: 0 },
              }}
              startIcon={<CheckCircle />}
              onClick={handleMarkAsResolved}
            >
              Marcar como Resuelto
            </Button>
          )}
        </Box>

        <IncidentDetailView
          incident={incident}
          onUpdateStatus={handleUpdateStatus}
          onAddComment={handleComment}
          onRateResolution={(rating, comment) => {
            // Implement rating
          }}
        />
      </Box>
    </Container>
  );
};

export default IncidentDetail;