// src/features/auth/pages/Login.js

import { useState } from "react";
import { Typography, Box, Link, Snackbar } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { useAuth } from "../hooks/useAuth";

import { ROUTES } from "../../../config/routes";
import trimText from "../../../shared/utils/trimText";

interface LoginCredentials {
  email: string;
  password: string;
}

const Login = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ email, password }: LoginCredentials) => {
    try {
      setLoading(true);
      const success = await login(trimText(email), trimText(password));

      if (success) {
        setSnackbarMessage("Login successful");
        navigate(ROUTES.DASHBOARD);
      } else {
        setSnackbarMessage("Login failed");
      }
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error("Login error:", error);
      setSnackbarMessage(error.message || "An error occurred during login");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: "auto", p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Iniciar sesión
      </Typography>
      <LoginForm onSubmit={handleLogin} loading={loading} />
      <Typography align="center">
        <Link
          component={RouterLink}
          to={ROUTES.FORGOT_PASSWORD}
          underline="hover"
        >
          Olvidé mi contraseña
        </Link>
      </Typography>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Login;
