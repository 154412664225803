import { useBuildingAnalytics } from "./useBuildingAnalytics";
import useGlobalStore from "../../state/store";

export const useBuilding = () => {
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setApartmentId = useGlobalStore((state) => state.setApartmentId);
  const apartmentId = useGlobalStore((state) => state.apartmentId);
  const setMaintenanceFees = useGlobalStore(
    (state) => state.setMaintenanceFees
  );

  const { trackApartmentSelected } = useBuildingAnalytics();

  const selectApartment = async (apartmentId: string) => {
    setApartmentId(apartmentId);
    trackApartmentSelected(apartmentId, buildingId!);
  };

  return {
    buildingId,
    setBuildingId,
    apartmentId,
    setApartmentId,
    selectApartment,
  };
};
