// src/features/manualVisitRegistration/pages/ManualVisitRegistration.js

import React, { useState } from "react";
import { Box, Typography, Snackbar, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DocumentInput from "../components/DocumentInput";
import NewVisitorRegistration from "../components/NewVisitorRegistration";
import ExistingVisitorRegistration from "../components/ExistingVisitorRegistration";
import { manualVisitRegistrationService } from "../services/manualVisitRegistrationService";
import { useNavigation } from "../../../navigation/hooks/useNavigation"; // Import the useNavigation hook
import { ROUTES } from "../../../config/routes"; // Import the ROUTES object
import { Link } from "react-router-dom";

const ManualVisitRegistration = () => {
  const [step, setStep] = useState(1);
  const [visitor, setVisitor] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [unfoundDocumentNumber, setUnfoundDocumentNumber] = useState("");

  const handleDocumentSubmit = async (documentNumber) => {
    try {
      const visitorData =
        await manualVisitRegistrationService.findVisitorByDocument(
          documentNumber
        );
      if (visitorData) {
        setVisitor(visitorData);
        setStep(3);
      } else {
        setUnfoundDocumentNumber(documentNumber);
        setInfoMessage(
          "Usuario no encontrado. Se procederá a registrar un nuevo visitante."
        );
        setStep(2);
      }
    } catch (error) {
      console.error("Error fetching visitor:", error);
      setErrorMessage(
        "Error al buscar el visitante. Por favor, intente de nuevo."
      );
    }
  };

  const handleNewVisitorSubmit = (newVisitor) => {
    setVisitor(newVisitor);
    setInfoMessage(
      "Visitante registrado exitosamente. Proceda a registrar la visita."
    );
    setStep(3);
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setInfoMessage("");
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Link to={ROUTES.DASHBOARD}>
          <IconButton sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography
          variant="h5"
          component="h1"
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          Registro manual de visita
        </Typography>
      </Box>
      {step === 1 && <DocumentInput onSubmit={handleDocumentSubmit} />}
      {step === 2 && (
        <NewVisitorRegistration
          onSubmit={handleNewVisitorSubmit}
          initialDni={unfoundDocumentNumber}
        />
      )}
      {step === 3 && visitor && (
        <ExistingVisitorRegistration visitor={visitor} />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
      />
      <Snackbar
        open={!!infoMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={infoMessage}
      />
    </Box>
  );
};

export default ManualVisitRegistration;
