// src/features/auth/services/authService.js

import { supabase } from '../../../supabase/client';

export interface UserProfileData {
  dni?: string;
  documentType?: string;
  firstName: string;
  lastName?: string | null;
  phone?: string | null;
  userType?: string;
  email: string;
}

interface User {
  id: string;
  email?: string;
  user_metadata: any;
}



export const authService = {
  async signIn(email: string, password: string): Promise<User> {
    try
    {
      console.log('Attempting to sign in with email:', email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });
      if (error) throw error;
      console.log('Sign in successful, user data:', data.user);
      return data.user;
    } catch (error)
    {
      console.error('Sign in error:', error);
      throw error;
    }
  },

  async signOut() {
    try
    {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error)
    {
      console.error('Sign out error:', error);
      throw error;
    }
  },

  async signUpWithOtp(email) {
    const temporaryPassword = Math.random().toString(36).slice(-8);
    try
    {
      console.log('Attempting to sign up user with email:', email);
      const { data, error } = await supabase.auth.signUp({
        email,
        password: temporaryPassword,
        options: {
          data: {
            document_number: 'PENDING',
            is_active: true,
          }
        }
      });
      if (error) throw error;
      console.log('Signup successful. User data:', data);
      return data;
    } catch (error)
    {
      console.error('Unexpected error during signUp:', error);
      throw error;
    }
  },

  async getUserProfile(userId) {
    try
    {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('auth_user_id', userId)
        .single();

      if (error) throw error;

      // Check if user has worker roles
      const isWorker =
        Array.isArray(data.roles) &&
        (data.roles.includes("concierge") || data.roles.includes("manager"));

      return {
        ...data,
        is_worker: isWorker,
      };
    } catch (error)
    {
      console.error('Get user profile error:', error);
      throw error;
    }
  },

  async updateUserProfile(userId: string, userData: UserProfileData): Promise<any> {
    try
    {
      console.log('Upserting user profile for userId:', userId);

      const { data, error } = await supabase
        .from('users')
        // @ts-ignore
        .upsert({
          auth_user_id: userId,
          document_number: userData.dni || 'PENDING',
          document_type: userData.documentType || 'dni',
          first_name: userData.firstName,
          last_name: userData.lastName || null,
          phone: userData.phone || null,
          user_type: userData.userType || 'visitor',
          email: userData.email,
          is_active: true,
          roles: ['visitor'],
        }, {
          onConflict: 'auth_user_id',
          returning: 'representation'
        })
        .select();

      if (error)
      {
        console.error('Supabase error:', error);
        throw error;
      }

      if (!data || data.length === 0)
      {
        throw new Error('Failed to upsert user profile: No data returned');
      }

      console.log('User profile upserted successfully:', data[0]);
      return data[0];
    } catch (error)
    {
      console.error('Error upserting user profile:', error);
      throw error;
    }
  },

  async updateUserPassword(password) {
    try
    {
      console.log('Starting password update process');
      const { data, error } = await supabase.auth.updateUser({ password });
      if (error) throw error;
      console.log('Password updated successfully for user:', data.user.id);
      return data.user;
    } catch (error)
    {
      console.error('Error in updateUserPassword:', error);
      throw error;
    }
  },

  async getUserResidenceStructure(userId) {
    try
    {
      const { data: residentData, error: residentError } = await supabase
        .from('resident_apartments')
        .select(`
          id,
          apartment: apartments (
            id,
            apartment_number,
            building: buildings (
              id,
              name,
              address
            )
          )
        `)
        .eq('resident_id', userId)
        .eq('is_active', true);

      if (residentError) throw residentError;

      // Transform the flat data into a hierarchical structure
      const buildings = residentData.reduce((acc, { apartment }: any) => {
        if (!apartment) return acc;

        const building = apartment.building;
        if (!building) return acc;

        const buildingId = building.id;
        if (!buildingId) return acc;

        if (!acc.find(b => b.id === buildingId))
        {
          acc.push({
            id: buildingId,
            name: building.name,
            address: building.address,
            apartments: []
          });
        }
        // @ts-ignore        
        const buildingIndex = acc.findIndex(b => b.id === buildingId);
        // @ts-ignore  
        acc[buildingIndex].apartments.push({
          // @ts-ignore  
          id: apartment.id,
          // @ts-ignore  
          apartment_number: apartment.apartment_number
        });

        return acc;
      }, []);

      console.log('Structured building data:', buildings);
      return buildings;
    } catch (error)
    {
      console.error('Error fetching user residence structure:', error);
      throw error;
    }
  },

  async getUserBuildings(userId) {
    try
    {
      const { data, error } = await supabase
        .from("worker_buildings")
        .select(
          `
          building:buildings (
            id,
            name,
            address,
            apartments (
              id,
              apartment_number
            )
          )
        `
        )
        .eq("worker_id", userId)
        .eq("is_active", true);

      if (error) throw error;
      // Transform the data
      const buildings = (data || [])
        .filter((item: any) => item.building) // Filter out any null buildings
        .map((item: any) => ({
          id: item.building.id,
          name: item.building.name,
          address: item.building.address,
          apartments: item.building?.apartments || [],
        }));

      return buildings;
    } catch (error)
    {
      console.error("Error fetching worker buildings:", error);
      throw error;
    }
  },

  async getSession() {
    try
    {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      return data.session;
    } catch (error)
    {
      console.error("Get session error:", error);
      throw error;
    }
  },

  async retrieveUser() {
    try
    {
      const { data, error } = await supabase.auth.getUser();
      if (error) throw error;
      return data.user;
    } catch (error)
    {
      console.error("Get user error:", error);
      throw error;
    }
  },

  
  // TODO: Improve method, made with AI
  async resetPassword(email: string) {
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email,
        {
          redirectTo: `${window.location.origin}/reset-password`,
      
        }
      );

      if (error) {
        console.error("Password reset error:", error);
        throw error;
      }
      return data;
    } catch (error) {
      console.error("Error in resetPassword:", error);
      throw error;
    }
  },
};
