import { useEffect, useState } from "react";
import useGlobalStore from "../../../state/store";
import { supabase } from "../../../supabase/client";
import { useLocation } from "react-router-dom";
import { Session } from "@supabase/supabase-js";

export function getSession() {
  return useGlobalStore.getState().session;
}

export const isSessionExpired = (session: Session | null): boolean => {
  if (!session) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);

  return currentTime >= session.expires_at;
};

function useSession() {
  const cachedSession = useGlobalStore((state) => state.session);
  const setSession = useGlobalStore((state) => state.setSession);
  const [authenticating, setAuthenticating] = useState(true);
  const isAuthenticated = cachedSession !== null;
  const location = useLocation();

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setAuthenticating(false);
    });

    return () => subscription.unsubscribe();
  }, [location.pathname]);

  return {
    session: cachedSession,
    setSession,
    isAuthenticated,
    authenticating,
    sessionExpired: isSessionExpired(cachedSession),
  };
}

export default useSession;
