// src/storage/hooks/useStorage.js
import { useState, useCallback } from 'react';
import { supabaseStorage } from '../services/supabaseStorage';

export const useStorage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const uploadFile = useCallback(async (file, prefix) => {
    setLoading(true);
    setError(null);
    try {
      const result = await supabaseStorage.uploadFile(file, prefix);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const uploadFiles = useCallback(async (files, prefix) => {
    setLoading(true);
    setError(null);
    try {
      const results = await supabaseStorage.uploadMultipleFiles(files, prefix);
      return results;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteFile = useCallback(async (filePath) => {
    setLoading(true);
    setError(null);
    try {
      await supabaseStorage.deleteFile(filePath);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteFiles = useCallback(async (filePaths) => {
    setLoading(true);
    setError(null);
    try {
      await supabaseStorage.deleteMultipleFiles(filePaths);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const getFileUrl = useCallback((filePath) => {
    try {
      return supabaseStorage.getFileUrl(filePath);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }, []);

  const moveFile = useCallback(async (oldPath, newPath) => {
    setLoading(true);
    setError(null);
    try {
      await supabaseStorage.moveFile(oldPath, newPath);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const copyFile = useCallback(async (sourcePath, destinationPath) => {
    setLoading(true);
    setError(null);
    try {
      await supabaseStorage.copyFile(sourcePath, destinationPath);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const listFiles = useCallback(async (prefix = '') => {
    setLoading(true);
    setError(null);
    try {
      const files = await supabaseStorage.listFiles(prefix);
      return files;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    uploadFile,
    uploadFiles,
    deleteFile,
    deleteFiles,
    getFileUrl,
    moveFile,
    copyFile,
    listFiles
  };
};
