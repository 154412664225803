import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon, Download as DownloadIcon } from "@mui/icons-material";
import { MeterRow } from "./MeterTable";
interface MeterDetailDialogProps {
  open: boolean;
  meter: MeterRow | null;
  onClose: () => void;
  onSaveChanges: (updatedMeter: MeterRow) => void;
}

const MeterDetailDialog: React.FC<MeterDetailDialogProps> = ({
  open,
  meter,
  onClose,
  onSaveChanges,
}) => {
  // Manejo local del valor de lectura actual (final).
  // Si meter existe, inicializamos con meter.final; si no, con ""
  const [finalReading, setFinalReading] = useState<number | "">("");

  // Cada vez que cambie la prop 'meter', actualizamos el estado local
  React.useEffect(() => {
    if (meter) {
      setFinalReading(meter.final);
    }
  }, [meter]);

  if (!meter) return null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    // Aceptar solo valores >= 0, por ejemplo
    if (!isNaN(value) && value >= 0) {
      setFinalReading(value);
    } else if (event.target.value === "") {
      // Permitir que el usuario borre todo momentáneamente
      setFinalReading("");
    }
  };

  const handleSave = () => {
    // 1. Crear objeto actualizado
    const updatedMeter = {
      ...meter,
      final: typeof finalReading === "number" ? finalReading : meter.final,
      // Podrías recalcular "consumption" aquí o dejar que el backend lo maneje
      consumption:
        typeof finalReading === "number"
          ? finalReading - meter.initial
          : meter.consumption,
    };
    // 2. Llamar la función del padre
    onSaveChanges(updatedMeter);
    onClose();

  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Detalle Registro de Lectura</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Fecha de registro: {new Date(meter.created_at).toLocaleString("es-ES")}
        </Typography>

        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Información de lectura
          </Typography>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              padding: "16px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Departamento:</strong> {meter.apartment}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Período:</strong> {meter.period}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Lectura anterior:</strong> {meter.initial} m³
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Lectura actual:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={finalReading}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>
                        m³
                      </Typography>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Consumo:</strong>{" "}
                  {typeof finalReading === "number"
                    ? finalReading - meter.initial
                    : meter.consumption}{" "}
                  m³
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Foto del contador
          </Typography>
          <Box
            component="img"
            src={meter.image_url || "https://via.placeholder.com/400x300?text=No+Image"}
            alt="Foto del contador"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              border: "1px solid #e0e0e0",
            }}
          />
          <Box mt={1} display="flex" justifyContent="flex-end" gap={1}>
            <IconButton color="error">
              <DeleteIcon />
            </IconButton>
            <IconButton color="primary"
            onClick={async () => {
              try {
                // 1. Hacemos el fetch de la imagen
                const response = await fetch(meter.image_url || "https://via.placeholder.com/400x300");
                if (!response.ok) {
                  throw new Error("No se pudo descargar la imagen");
                }
                const blob = await response.blob();         
                const objectURL = URL.createObjectURL(blob);
                const fileName = `${meter.apartment}_${meter.period}.jpg`;
                const link = document.createElement("a");
                link.href = objectURL;
                link.download = fileName;
                link.click();
          
                URL.revokeObjectURL(objectURL);
              } catch (error) {
                console.error("Error descargando la imagen:", error);
              }
            }}>
              <DownloadIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Botón para guardar */}
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeterDetailDialog;
