import { Grid, Paper, Typography } from '@mui/material';
import { getExpirationDate, getGoalToRaise, getMonthNameByNumber, getTotalAppartments } from '../../utils';


interface SummaryCardsProps {
  fileData: any[];
}

export default function  MaintenanceFeeSummaryCard({ fileData }: SummaryCardsProps) {
  const summaryCards = [
    { label: 'Periodo', value: getMonthNameByNumber(fileData[0].fecha) },
    { label: 'Fecha de vencimiento', value: getExpirationDate(fileData[0].fecha) },
    { label: 'Meta a Recaudar', value: getGoalToRaise(fileData) },
    { label: 'Cantidad de departamentos', value: getTotalAppartments(fileData) },
  ];

  return (
    <Grid container spacing={3} mb={5}>
      {summaryCards.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper
            sx={{
              p: 2,
              textAlign: 'center',
              backgroundColor: '#e5e7eb',
              boxShadow: 'none',
              minHeight: '120px',
              alignContent: 'center',
              borderRadius: '16px',
            }}
          >
            <Typography mb={0.5}>{card.label}</Typography>
            <Typography variant="h6" fontWeight="bold">
              {card.value}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
