
// src/supabase/services/realtimeChannelManager.ts
import { RealtimeChannel, RealtimeChannelOptions } from '@supabase/supabase-js';

export type ChannelConfig = {
  event?: string;
  filter?: string;
  callback?: (payload: any) => void;
  options?: RealtimeChannelOptions;
};

type SubscriptionState = {
  channelName: string;
  subscribed: boolean;
}

export class RealtimeChannelManager {
  private channels: Map<string, RealtimeChannel>;
  private configs: Map<string, ChannelConfig>;
  private subscriptionStates: Map<string, SubscriptionState>;

  constructor() {
    this.channels = new Map();
    this.configs = new Map();
    this.subscriptionStates = new Map();
  }

  setChannel(name: string, channel: RealtimeChannel, config?: ChannelConfig) {
    this.channels.set(name, channel);
    if (config) {
      this.configs.set(name, config);
    }
    this.subscriptionStates.set(name, { 
      channelName: name, 
      subscribed: true 
    });
  }

  getChannel(name: string) {
    return this.channels.get(name);
  }

  getConfig(name: string) {
    return this.configs.get(name);
  }

  isSubscribed(name: string): boolean {
    return this.subscriptionStates.get(name)?.subscribed || false;
  }

  async removeChannel(name: string) {
    const channel = this.channels.get(name);
    if (channel) {
      await channel.unsubscribe();
      this.channels.delete(name);
      this.configs.delete(name);
      this.subscriptionStates.delete(name);
    }
  }

  async cleanup() {
    const promises = Array.from(this.channels.values()).map(
      channel => channel.unsubscribe()
    );
    await Promise.all(promises);
    this.channels.clear();
    this.configs.clear();
    this.subscriptionStates.clear();
  }
}