import { meterService } from "../services/meterService";
import useGlobalStore from "../../../state/store";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { WaterConsumption } from "../types";

export const useWaterConsumptions = () => {
  
  const waterConsumptions = useGlobalStore((state) => state.waterConsumptions);
  const setWaterConsumptions = useGlobalStore(
    (state) => state.setWaterConsumptions
  );
  const hasCachedWaterConsumptions = isValidNonEmptyArray(waterConsumptions.data);

  const getWaterConsumptions = async (waterConsumptionPeriod: string,   buildingId: string) => {
    try {
      setWaterConsumptions({data:[], loading: true, error: null });

      const data = await meterService.getMeters(buildingId, waterConsumptionPeriod);
  

      setWaterConsumptions({ data });
    } catch (error) {
      setWaterConsumptions({ error: "Error getting water consumption." });
    } finally {
      setWaterConsumptions({ loading: false });
    }
  };

  const updateWaterConsumption = async ({
    waterConsumption,
    waterConsumptionPeriod,
    buildingId
  }: {
    waterConsumption: WaterConsumption;
    waterConsumptionPeriod: string;
    buildingId: string;
  }) => {
    try {
      await meterService.updateMeter(waterConsumption);
      await getWaterConsumptions(waterConsumptionPeriod, buildingId);
    } catch (error) {
      setWaterConsumptions({
        error: "Error updating water consumption.",
      });
    }
  };

  return {
    waterConsumptions: waterConsumptions.data ?? [],
    loading: waterConsumptions.loading,
    error: waterConsumptions.error ?? null,
    updateWaterConsumption,
    hasCachedWaterConsumptions: isValidNonEmptyArray(waterConsumptions.data),
    getWaterConsumptions,
  };
};
