// src/features/invitation/components/HistoricalVisits.js

import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import { Visibility, UnfoldMore } from "@mui/icons-material";
import { format, isPast, isToday, parseISO } from "date-fns";
import NoVisitsMessage from "./NoVisitsMessage";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import { Link } from "react-router-dom";

const HistoricalVisits = ({ visits }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "fecha",
    direction: "desc",
  });
  const { navigateWithParams } = useNavigation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const historicalVisits = useMemo(
    () =>
      visits.filter((visit) => {
        const visitDate = visit.invitation_date || visit.check_in_time;
        return (
          visitDate &&
          isPast(parseISO(visitDate)) &&
          !isToday(parseISO(visitDate))
        );
      }),
    [visits]
  );

  const sortedVisits = useMemo(() => {
    let sortableVisits = [...historicalVisits];
    if (sortConfig.key) {
      sortableVisits.sort((a, b) => {
        if (sortConfig.key === "nombre") {
          const nameA =
            `${a.visitor_first_name} ${a.visitor_last_name}`.toLowerCase();
          const nameB =
            `${b.visitor_first_name} ${b.visitor_last_name}`.toLowerCase();
          return sortConfig.direction === "asc"
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        }
        if (sortConfig.key === "depto.") {
          return sortConfig.direction === "asc"
            ? a.apartment_number - b.apartment_number
            : b.apartment_number - a.apartment_number;
        }
        if (["fecha", "hora de ingreso"].includes(sortConfig.key)) {
          const dateA = new Date(a.invitation_date || a.check_in_time);
          const dateB = new Date(b.invitation_date || b.check_in_time);
          return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        return 0;
      });
    }
    return sortableVisits;
  }, [historicalVisits, sortConfig]);

  const filteredVisits = useMemo(
    () =>
      sortedVisits.filter(
        (visit) =>
          `${visit.visitor_first_name} ${visit.visitor_last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          visit.apartment_number.toString().includes(searchTerm)
      ),
    [sortedVisits, searchTerm]
  );

  const handleSort = (key: string) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const getVisitDetailsPath = (visitId: string) =>
    `${ROUTES.VISIT_DETAIL.replace(":visitId", visitId)}?from=history`;

  if (historicalVisits.length === 0) {
    return <NoVisitsMessage isActiveTab={false} />;
  }

  const tableHeaders = isMobile
    ? [
        { name: "Nombre", key: "nombre", width: "35%" },
        { name: "Depto.", key: "depto.", width: "25%" },
        { name: "Fecha", key: "fecha", width: "30%" },
        { name: "", key: "acciones", width: "10%" },
      ]
    : [
        { name: "Nombre", key: "nombre", width: "35%" },
        { name: "Depto.", key: "depto.", width: "15%" },
        { name: "Fecha", key: "fecha", width: "25%" },
        { name: "Hora de ingreso", key: "hora de ingreso", width: "20%" },
        { name: "", key: "acciones", width: "5%" },
      ];

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Buscar por nombre o departamento"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TableContainer
        component={Paper}
        sx={{ bgcolor: "#FFFFFF", borderRadius: "8px", overflow: "hidden" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  key={header.key}
                  align="left"
                  onClick={() =>
                    header.key !== "acciones" && handleSort(header.key)
                  }
                  sx={{
                    fontWeight: "bold",
                    color: "#001943",
                    bgcolor: "#E9EDFF",
                    cursor: header.key !== "acciones" ? "pointer" : "default",
                    "&:hover":
                      header.key !== "acciones" ? { bgcolor: "#D8DEFF" } : {},
                    borderBottom: `1px solid #315CAC`,
                    width: header.width,
                    padding: isMobile ? "8px 4px" : "12px 8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {header.name}
                    {header.key !== "acciones" && (
                      <UnfoldMore
                        sx={{
                          fontSize: 16,
                          color:
                            sortConfig.key === header.key
                              ? "#001943"
                              : "#A0A0A0",
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVisits.map((visit) => (
              <TableRow
                key={visit.visit_id}
                sx={{ "&:hover": { bgcolor: "#F5F7FF" } }}
              >
                <TableCell
                  align="left"
                  sx={{
                    color: "#44464F",
                    padding: isMobile ? "8px 4px" : "12px 8px",
                  }}
                >
                  {visit.visitor_first_name} {visit.visitor_last_name}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#44464F",
                    padding: isMobile ? "8px 4px" : "12px 8px",
                  }}
                >
                  {visit.apartment_number}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#44464F",
                    padding: isMobile ? "8px 4px" : "12px 8px",
                  }}
                >
                  {format(
                    parseISO(visit.invitation_date || visit.check_in_time),
                    "dd/MM/yy"
                  )}
                  {isMobile && (
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{ color: "#44464F" }}
                    >
                      {format(
                        parseISO(visit.check_in_time || visit.invitation_date),
                        "HH:mm"
                      )}
                    </Typography>
                  )}
                </TableCell>
                {!isMobile && (
                  <TableCell
                    align="left"
                    sx={{ color: "#44464F", padding: "12px 8px" }}
                  >
                    {format(
                      parseISO(visit.check_in_time || visit.invitation_date),
                      "HH:mm"
                    )}
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{ padding: isMobile ? "8px 4px" : "12px 8px" }}
                >
                  <Link to={getVisitDetailsPath(visit.visit_id)}>
                    <IconButton
                      size="small"
                      sx={{
                        padding: "4px",
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                    >
                      <Visibility sx={{ color: "#315CAC" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HistoricalVisits;
