import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { useAuthAnalytics } from '../hooks/useAuthAnalytics';
import { ROUTES } from '../../../config/routes';

const ResetPassword: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const { trackPasswordResetCompleted } = useAuthAnalytics();

  useEffect(() => {
    const handlePasswordRecovery = () => {
      const fragment = new URLSearchParams(window.location.hash.slice(1));
      const token = fragment.get('access_token');
      const error = fragment.get('error');
      
      if (token) {
        setAccessToken(token);
      } else {
        navigate(ROUTES.FORGOT_PASSWORD);
      }
    };
  
    handlePasswordRecovery();
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!accessToken) {
      setSnackbarMessage('No se encontró un token válido.');
      setOpenSnackbar(true);
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarMessage('Las contraseñas no coinciden.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await authService.updateUserPassword(password);
      trackPasswordResetCompleted();
      setSnackbarMessage('Contraseña actualizada exitosamente.');
      setOpenSnackbar(true);
      setTimeout(() => navigate(ROUTES.LOGIN), 2000);
    } catch (error: any) {
      setSnackbarMessage(error.message || 'Error al actualizar la contraseña.');
      setOpenSnackbar(true);
      setTimeout(() => navigate(ROUTES.LOGIN), 3000);
    }
  };

  if (!accessToken) {
    return null;
  }

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Restablecer contraseña
      </Typography>
      
      <form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          name="password"
          label="Nueva contraseña"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          fullWidth
          name="confirmPassword"
          label="Confirmar nueva contraseña"
          type={showConfirmPassword ? 'text' : 'password'}
          id="confirmPassword"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Actualizar contraseña
        </Button>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ResetPassword;