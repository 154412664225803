export default function isValidNonEmptyObject(value: unknown) {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  if (Object.keys(value).length === 0) {
    return false;
  }

  return true;
}
