// src/features/maintenanceFee/hooks/useMaintenanceFee.ts

import { maintenanceFeeService } from "../services/maintenanceFeeService";
import useGlobalStore from "../../../state/store";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { Charge, MaintenanceFee, Payment } from "../types";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";

export const useMaintenanceFee = () => {
  const maintenanceFees = useGlobalStore((s) => s.maintenanceFees);
  const setMaintenanceFees = useGlobalStore((s) => s.setMaintenanceFees);

  const setApartments = useGlobalStore((s) => s.setApartments);
  const baseApartments = useGlobalStore((s) => s.apartments);

  const hasCachedMaintenanceFees = isValidNonEmptyArray(maintenanceFees.data);
  

  const fetchMaintenanceData = async ({
    buildingId,
    period,
  }: {
    buildingId: string;
    period: string;
  }) => {
    if (!buildingId) {
      return setMaintenanceFees({
        error: "Please select building to get maintenance fees. ",
      });
    }
    setMaintenanceFees({ loading: true, error: null });
    try {
      let apartmentData = [...baseApartments];

      const areFromSameBuilding =
        apartmentData.length > 0 && apartmentData[0].buildingId === buildingId;

      if (apartmentData.length === 0 || !areFromSameBuilding) {
        const newApartments =
          await maintenanceFeeService.fetchApartmentsByBuildingId(buildingId);

        if (newApartments.length !== apartmentData.length) {
          setApartments(newApartments);
        }

        apartmentData = newApartments;
      }

      const selectedDateObj = new Date(`${period}-01T12:00:00`);
      if (isNaN(selectedDateObj.getTime())) {
        throw new Error("Invalid selected date");
      }
      const startDate = format(startOfMonth(selectedDateObj), "yyyy-MM-dd");
      const endDate = format(endOfMonth(selectedDateObj), "yyyy-MM-dd");

      const [maintenanceData, paymentsData] = await Promise.all([
        maintenanceFeeService.fetchMaintenanceData(buildingId, period),
        maintenanceFeeService.fetchPayments(buildingId, { startDate, endDate }),
      ]);

      const chargesByApartment: Record<string, Charge[]> = {};
      maintenanceData.charges.forEach((charge: Charge) => {
        if (!chargesByApartment[charge.apartmentNumber]) {
          chargesByApartment[charge.apartmentNumber] = [];
        }
        chargesByApartment[charge.apartmentNumber].push(charge);
      });

      const paymentsByApartment: Record<string, Payment[]> = {};
      paymentsData.payments.forEach((payment: Payment) => {
        if (!paymentsByApartment[payment.apartmentId]) {
          paymentsByApartment[payment.apartmentId] = [];
        }
        paymentsByApartment[payment.apartmentId].push(payment);
      });

      const processedData: MaintenanceFee[] = apartmentData.map((apt: any) => {
        const aptCharges = chargesByApartment[apt.apartmentNumber] || [];
        const aptPayments = paymentsByApartment[apt.id] || [];

        const emiCuota = aptCharges
          .filter((c) => c.charge_type === "maintenance_fee")
          .reduce((sum, c) => sum + (c.total_amount || 0), 0);

        const totalCharges = aptCharges.reduce(
          (sum, c) => sum + (c.total_amount || 0),
          0
        );

        const montoPagado = aptPayments
          .filter((p) => p.status === "verified")
          .reduce((sum, p) => sum + (p.amount || 0), 0);

        const porValidar = aptPayments
          .filter((p) => p.status === "pending")
          .reduce((sum, p) => sum + (p.amount || 0), 0);

        const deuda = Math.max(0, totalCharges - montoPagado);

        const residentName = apt.residents?.[0]
          ? `${apt.residents[0].first_name} ${apt.residents[0].last_name}`
          : "Sin residente";

        return {
          id: apt.id,
          apartmentNumber: apt.apartmentNumber,
          residentName,
          emiCuota,
          montoPagado,
          porValidar,
          deuda,
          payments: aptPayments,
          charges: aptCharges,
          residents: apt.residents,
        };
      });

      setMaintenanceFees({ data: processedData });
    } catch (err) {
      console.error("Error fetching maintenance data:", err);
      setMaintenanceFees({
        error: err instanceof Error ? err.message : "Error loading data",
      });
    } finally {
      setMaintenanceFees({ loading: false });
    }
  };

  return {
    apartments: maintenanceFees.data ?? [],
    loading: maintenanceFees.loading,
    error: maintenanceFees.error,
    fetchMaintenanceData,
    hasCachedMaintenanceFees,
  };
};
