interface TrimOptions {
  removeInternalSpaces?: boolean;
}

/**
 * Trims whitespace from the beginning and end of a string
 * @param text - The input text to trim
 * @param options - Optional configuration
 * @returns Trimmed text
 */
export default function trimText(
  text: unknown,
  options: TrimOptions = { removeInternalSpaces: true }
): string {
  if (typeof text !== "string") {
    return String(text);
  }

  // Trim start and end whitespace
  let trimmedText = text.trim();

  // Optionally remove internal spaces
  if (options.removeInternalSpaces) {
    trimmedText = trimmedText.replace(/\s+/g, "");
  }

  return trimmedText;
}
