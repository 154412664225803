import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useAuth } from "../../features/auth/hooks/useAuth";
import { useAnalytics } from "../../analytics/hooks/useAnalytics";
import MainSidebar from "./MainSidebar";
import MobileSidebar from "./MobileSidebar"; // Import MobileSidebar
import LoadingSpinner from "../components/LoadingSpinner";
import useSession from "../../features/auth/hooks/useSession";
import MainTopBar from "./MainTopBar";

export default function MainLayout() {
  const theme = useTheme();
  const { isAuthenticated } = useSession();
  const { user, isSynchUser } = useAuth();
  const { identify, trackError } = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Initialize the sidebar closed on mobile, open on desktop.
  const [sidebarOpen, setSidebarOpen] = React.useState(!isMobile);

  useEffect(() => {
    if (user && isAuthenticated) {
      identify(user.id, { email: user.email });
    }
  }, [user, isAuthenticated, identify]);

  useEffect(() => {
    const handleError = (event: any) => {
      trackError(event.error.message, event.error.stack);
    };
    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, [trackError]);

  // This function toggles the sidebar open/closed.
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Box
      bgcolor={"#F4F8FA"}
      sx={{ display: "flex", height: "100vh", paddingBottom: 4 }}
    >
      <CssBaseline />

      {/* Pass the toggle function to the TopBar */}
      <MainTopBar handleSidebarToggle={handleSidebarToggle} />

      {/* Conditionally render MainSidebar or MobileSidebar based on screen size */}
      {isMobile ? (
        <MobileSidebar open={sidebarOpen} handleCloseSidebar={handleSidebarToggle} />
      ) : (
        <MainSidebar open={sidebarOpen} handleCloseSidebar={handleSidebarToggle} />
      )}

      <Box
        borderRadius={4}
        component="main"
        sx={{
          flex: 1,
          width: "100%",
          p: 4,
          overflow: "scroll",
          backgroundColor: "white",
          marginLeft: 2,
          marginRight: 3,
          marginTop: 8, // Offset main component to avoid being overlaid by the AppBar
        }}
      >
        {isSynchUser ? <LoadingSpinner /> : <Outlet />}
      </Box>
    </Box>
  );
}