// src/features/maintenanceFee/services/maintenanceFeeConfigService.js

import { secureApi } from "../../../supabase/secureApi";

export const maintenanceFeeConfigService = {
  async fetchBuildingFeeSettings(buildingId) {
    // You might have a custom RPC or just a `select` from building_fee_settings
    const [row] = await secureApi.get("building_fee_settings", {
      filter: { building_id: buildingId },
      single: false, // We want the array, but it should have exactly 1 row
    });
    return row;
  },

  async upsertBuildingFeeSettings(buildingId, data) {
    // If the row does not exist, insert it. If it exists, update it.
    // One approach is to check if the row exists, then post or patch.
    // Or do it in a single RPC. For simplicity, we do an update/insert logic here:

    // 1) see if row exists
    const existing = await this.fetchBuildingFeeSettings(buildingId);
    if (existing) {
      // update
      const updated = await secureApi.update("building_fee_settings", existing.id, data);
      return updated;
    } else {
      // insert
      const inserted = await secureApi.post("building_fee_settings", {
        building_id: buildingId,
        ...data,
      });
      return inserted;
    }
  },
};
