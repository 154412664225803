import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import { getRandomNumber } from "../../../../shared/utils/getRandomNumber";

function MaintenanceFeeRowSkeleton() {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "24px",
        mb: 2,
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
      }}
    >
      {/* Fila principal */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          "&:hover": {
            backgroundColor: "#F9FAFB",
          },
        }}
      >
        {/* Ícono de estado */}
        <Skeleton width={20} height={20} variant="circular" sx={{ mr: 3 }} />

        {/* Info de Depto */}
        <Box sx={{ width: "200px", mr: 4 }}>
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={20} />
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={16} />
        </Box>

        {/* Emi. Cuota M. */}
        <Box sx={{ width: "150px", mr: 4 }}>
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={20} />
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={16} />
        </Box>

        {/* Monto Pagado */}
        <Box sx={{ width: "150px", mr: 4 }}>
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={20} />
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={16} />
        </Box>

        {/* Por Validar */}
        <Box sx={{ width: "150px", mr: 4 }}>
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={26} />
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={16} />
        </Box>

        {/* Deuda */}
        <Box sx={{ width: "150px", mr: 4 }}>
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={26} />
          <Skeleton width={getRandomNumber(50, 100) + "%"} height={16} />
        </Box>

        {/* Acciones */}
        <Box sx={{ display: "flex", alignItems: "center", ml: "auto", gap: 1 }}>
          <Skeleton width={100} height={40} />
          <Skeleton width={20} height={20} variant="circular" sx={{ mx: 2 }} />
        </Box>
      </Box>
    </Box>
  );
}

export default MaintenanceFeeRowSkeleton;
