// src/features/auth/services/enhancedAuthService.ts

import { supabase } from "../../../supabase/client";

import { authService } from "./authService";

interface SignInWithPasswordParams {
  email: string;
  password: string;
}

interface SignInResponse {
  data?: {
    user: object;
    session: object;
  };
  error?: Error;
}

class EnhancedAuthService {
  async signInWithPassword({ email, password }: SignInWithPasswordParams) {
    try {
      const {
        data: { session },
        error,
      } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      if (!session) throw new Error("No session returned from sign-in");
    } catch (error: any) {
      console.error("Sign in error:", error);
      return { error };
    }
  }

  async signOut(): Promise<{ error: Error | null }> {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      return { error: null };
    } catch (error: any) {
      console.error("Sign out error:", error);
      return { error };
    }
  }
}

export const enhancedAuthService = new EnhancedAuthService();
