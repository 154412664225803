// src/supabase/secureApi.ts

import { getSession } from "../features/auth/hooks/useSession";

import { supabase } from "./client";

export const secureApi = {
  ensureAuth() {
    const session = getSession();

    if (!session) {
      throw new Error("No active session");
    }
  },
  async get(table: string, options: any = {}) {
    this.ensureAuth();

    let query = supabase.from(table).select(options.select || "*");

    if (options.filter) {
      Object.entries(options.filter).forEach(([key, value]) => {
        const [field, operator] = key.split("."); // Split 'issue_date.gte' into ['issue_date', 'gte']
        if (operator) {
          switch (operator) {
            case "gte":
              query = query.gte(field, value);
              break;
            case "lte":
              query = query.lte(field, value);
              break;
            case "gt":
              query = query.gt(field, value);
              break;
            case "lt":
              query = query.lt(field, value);
              break;
            case "neq":
              query = query.neq(field, value);
              break;
            case "in":
              if (Array.isArray(value)) {
                query = query.in(field, value);
              } else {
                console.warn(
                  `Expected array for 'in' filter on field '${field}', received:`,
                  value
                );
              }
              break;
            // Add more operators as needed
            default:
              console.warn(
                `Unsupported operator '${operator}' for field '${field}'. Defaulting to equality.`
              );
              query = query.eq(key, value);
          }
        } else {
          // No operator specified, default to equality
          query = query.eq(key, value);
        }
      });
    }

    if (options.single) {
      query = query.single();
    }

    if (options.order) {
      Object.entries(options.order).forEach(([key, direction]) => {
        query = query.order(key, { ascending: direction === "asc" });
      });
    }

    // Logging for debugging
    // console.log(`Fetching from table: ${table}`);
    // console.log('Select:', options.select || '*');
    // console.log('Filters:', options.filter);
    // console.log('Order:', options.order);
    // console.log('Single:', options.single);

    const { data, error } = await query;

    console.log({ data, error });

    if (error) {
      throw error;
    }

    return data;
  },
  async post(table: string, data: any) {
    this.ensureAuth();
    const { data: result, error } = await supabase
      .from(table)
      .insert(data)
      .select()
      .single();

    if (error) throw error;
    return result;
  },

  async update(table: string, id: string, data: any) {
    const { data: result, error } = await supabase
      .from(table)
      .update(data)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return result;
  },

  async delete(table: string, id: string) {
    this.ensureAuth();
    const { error } = await supabase.from(table).delete().eq("id", id);

    if (error) throw error;
  },
  async rpc(functionName: string, params: any) {
    this.ensureAuth();
    const { data, error } = await supabase.rpc(functionName, params);

    if (error) {
      throw error;
    }

    return data;
  },
};
