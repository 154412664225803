// src/supabase/client.ts
import { createClient } from "@supabase/supabase-js";
import { realtimeService } from './services/realtimeService';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  }
});

// Initialize realtime service
realtimeService.init();

export { realtimeService };