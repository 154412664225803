// src/features/auth/hooks/useAuth.js

import { useCallback } from "react";
import { useAuthAnalytics } from "./useAuthAnalytics";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { invitationService } from "../../invitation/services/invitationService";
import { ROUTES } from "../../../config/routes";
import { enhancedAuthService } from "../services/enhancedAuthService";
import { authService } from "../services/authService";
import useGlobalStore from "../../../state/store";
import useUser from "../../account/hooks/useUser";

export const useAuth = () => {
  const user = useGlobalStore((state) => state.user);
  const loading = useGlobalStore((state) => state.loading);
  const setUser = useGlobalStore((state) => state.setUser);

  const { purgeUser } = useUser();

  const { trackLogin, trackLogout, trackRegister } = useAuthAnalytics();
  const { navigateWithParams, clearParams, getParams } = useNavigation();

  const login = useCallback(
    async (email, password) => {
      try {
        await enhancedAuthService.signInWithPassword({
          email,
          password,
        });

        const { invitationToken, buildingId } = getParams();
        console.log("Params:", { invitationToken, buildingId });

        if (invitationToken) {
          try {
            console.log("Processing invitation");
            await invitationService.createVisitFromInvitation(
              invitationToken,
              fullUser.id
            );
            console.log("Invitation processed");
          } catch (inviteError) {
            console.error("Error processing invitation:", inviteError);
          }
        }

        console.log("Preparing navigation");
        if (buildingId) {
          console.log("Navigating to apartment selection");
          navigateWithParams(ROUTES.APARTMENT_SELECTION, { buildingId });
        } else {
          console.log("Navigating to dashboard");
          navigateWithParams(ROUTES.DASHBOARD);
        }

        return true; // Indicate successful login
      } catch (error) {
        console.error("Login error:", error);
        return false; // Indicate failed login
      }
    },
    [setUser, trackLogin, navigateWithParams, getParams]
  );

  const logout = useCallback(async () => {
    try {
      const { error } = await enhancedAuthService.signOut();
      if (error) throw error;

      setUser(null);
      trackLogout();
      clearParams();
      purgeUser();
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [setUser, trackLogout, clearParams, navigateWithParams]);

  const signUpWithOtp = useCallback(async (email) => {
    return authService.signUpWithOtp(email);
  }, []);

  const verifyOtp = useCallback(async (email, token) => {
    return authService.verifyOtp(email, token);
  }, []);

  const updateUserPassword = useCallback(async (password) => {
    return authService.updateUserPassword(password);
  }, []);

  const updateUserProfile = useCallback(async (userId, userData) => {
    return authService.updateUserProfile(userId, userData);
  }, []);

  const completeRegistration = useCallback(
    async (email, userInfo, password) => {
      try {
        console.log("Starting registration completion for email:", email);
        const updatedUser = await authService.updateUserPassword(password);
        if (!updatedUser) {
          throw new Error("Failed to update user password");
        }
        console.log("Password updated successfully for user:", updatedUser.id);

        const updatedProfile = await authService.updateUserProfile(
          updatedUser.id,
          {
            ...userInfo,
            email: updatedUser.email,
          }
        );

        if (!updatedProfile) {
          throw new Error("Failed to update user profile");
        }
        console.log("User profile updated successfully:", updatedProfile);

        const fullUser = { ...updatedUser, ...updatedProfile };
        setUser(fullUser);

        trackRegister(fullUser.id, { email: fullUser.email });
        console.log("User registered and tracked");

        const { invitationToken, buildingId } = getParams();
        console.log("Invitation token:", invitationToken);
        console.log("Building ID:", buildingId);
        if (invitationToken) {
          try {
            console.log(
              "Attempting to create visit with token:",
              invitationToken,
              "and user ID:",
              fullUser.id
            );
            const result = await invitationService.createVisitFromInvitation(
              invitationToken,
              fullUser.id
            );
            console.log("Visit creation result:", result);
          } catch (error) {
            console.error("Error creating visit:", error);
            // Consider how you want to handle this error. You might want to show a message to the user.
          }
        }

        console.log("Preparing navigation");
        if (buildingId) {
          console.log("Navigating to apartment selection");
          navigateWithParams(ROUTES.APARTMENT_SELECTION, { buildingId });
        } else {
          console.log("Navigating to dashboard");
          navigateWithParams(ROUTES.DASHBOARD);
        }

        return fullUser;
      } catch (error) {
        console.error("Registration completion error:", error);
        throw error;
      }
    },
    [getParams, navigateWithParams, trackRegister, setUser]
  );

  return {
    user,
    loading,
    login,
    logout,
    signUpWithOtp,
    verifyOtp,
    updateUserPassword,
    updateUserProfile,
    completeRegistration,
    setUser,
    isSynchUser: loading && !user,
  };
};
