import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Alert,
  Tabs,
  Tab,
} from "@mui/material";
import { Plus } from "lucide-react";
import { IncidentList, IncidentTable } from "../components/list";
import { useIncident } from "../hooks/useIncident";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import { isWithinInterval, startOfWeek, endOfWeek } from "date-fns";
import { incidentStyles } from "../../../styles/featuresStyles/incidentStyles";
import useGlobalStore from "../../../state/store";
import useIncidents from "../hooks/useIncidents";

import { Link } from "react-router-dom";
import { IncidentListSkeleton } from "../components/list/IncidentList";
import { useBuilding } from "../../../building/hooks/useBuilding";

const IncidentMain = () => {
  const { incidents, error, updateIncidentStatus } = useIncidents();
  const { navigateWithParams, getParams } = useNavigation();
  const [tabValue, setTabValue] = useState(0);
  const { buildingId } = useBuilding();

  const handleTabChange = (_event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const isWithinCurrentWeek = useCallback((date: any) => {
    if (!date) return false;
    const now = new Date();
    const weekStart = startOfWeek(now);
    const weekEnd = endOfWeek(now);

    return isWithinInterval(new Date(date), { start: weekStart, end: weekEnd });
  }, []);
  
  const incidentsData = incidents;

  const { activeIncidents, resolvedIncidents } = useMemo(() => {
    const active = incidentsData.filter(
      (inc: any) =>
        !inc.resolution_date || isWithinCurrentWeek(inc.resolution_date)
    );
    const resolved = incidentsData.filter(
      (inc: any) =>
        inc.resolution_date && !isWithinCurrentWeek(inc.resolution_date)
    );
    return { activeIncidents: active, resolvedIncidents: resolved };
  }, [incidentsData, isWithinCurrentWeek]);

  const handleIncidentClick = (incidentId: string) => {
    const params = getParams();
    navigateWithParams(`${ROUTES.INCIDENTS}/${incidentId}`, params);
  };

  return (
    <Container maxWidth="md">
      <Box sx={incidentStyles.container}>
        <Box sx={incidentStyles.header}>
          <Typography
            variant="h5"
            component="h1"
            sx={incidentStyles.headerTitle}
          >
            Mis Incidencias
          </Typography>

          <Link to={ROUTES.INCIDENT_NEW}>
            <Button
              variant="contained"
              startIcon={<Plus />}
              sx={incidentStyles.addButton}
            >
              Nueva Incidencia
            </Button>
          </Link>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="incident views"
          sx={incidentStyles.tabs}
          centered
        >
          <Tab label="En Proceso" />
          <Tab label="Resueltas" />
        </Tabs>

        <Box sx={incidentStyles.listContainer}>
          {incidents.loading  ? (
            <IncidentListSkeleton />
          ) : tabValue === 0 ? (
            <IncidentList
              incidents={activeIncidents}
              onStatusChange={(incidentId: string, status: string) =>
                buildingId &&
                updateIncidentStatus({ incidentId, status, buildingId })
              }
            />
          ) : (
            <IncidentTable
              incidents={resolvedIncidents}
              onStatusChange={(incidentId: string, status: string) =>
                buildingId &&
                updateIncidentStatus({ incidentId, status, buildingId })
              }
              onIncidentClick={handleIncidentClick}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default IncidentMain;
