import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Alert,
  Snackbar,
  Typography,
} from "@mui/material";
import { MediaUpload } from "../../../incidents/components/create";
import { useStorage } from "../../../../storage/hooks/useStorage";
import { maintenanceFeeService } from "../../services/maintenanceFeeService";
import { maintenanceFeeConfigService } from "../../services/maintenanceFeeConfigService";
import { supabaseStorage } from "../../../../storage"; // Added supabaseStorage import

const AddPaymentModal = ({ open, onClose, apartments = [], buildingId, onPaymentAdded }) => {
  const [apartmentId, setApartmentId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [concept, setConcept] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);

  const { uploadFiles } = useStorage();

  // Fetch bank accounts on mount
  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setLoadingAccounts(true);
        const settings = await maintenanceFeeConfigService.fetchBuildingFeeSettings(buildingId);
        setBankAccounts(settings?.bank_accounts || []);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
        setError("Error al cargar los métodos de pago");
      } finally {
        setLoadingAccounts(false);
      }
    };

    if (buildingId) {
      fetchBankAccounts();
    }
  }, [buildingId]);

  const resetForm = () => {
    setApartmentId("");
    setReferenceNumber("");
    setAmount("");
    setConcept("");
    setFiles([]);
    setSelectedBank("");
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!apartmentId || !amount || !referenceNumber || !selectedBank) {
      setError("Por favor complete todos los campos requeridos");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Find selected bank account details
      const selectedBankAccount = bankAccounts.find((acc) => acc.bank === selectedBank);
      if (!selectedBankAccount) {
        throw new Error("Banco seleccionado no válido");
      }

      // Upload files and get public URL if files exist
      let evidenceUrl = null;
      if (files.length > 0) {
        const path = `payments/${buildingId}/${apartmentId}`;
        const uploadedFiles = await uploadFiles(files, { path });
        if (uploadedFiles && uploadedFiles[0]) {
          // Get the public URL for the uploaded file
          const fileUrl = await supabaseStorage.getFileUrl(uploadedFiles[0].path, true);
          evidenceUrl = fileUrl;
        }
      }

      // Create payment data object
      const paymentData = {
        apartment_id: apartmentId,
        amount: parseFloat(amount),
        payment_date: new Date().toISOString().slice(0, 10),
        payment_method: selectedBankAccount.paymentMethod, // Use the payment_method from the account
        bank_name: selectedBankAccount.bank,
        reference_number: referenceNumber,
        evidence_url: evidenceUrl,
        notes: concept,
        status: "pending",
        building_id: buildingId,
        is_active: true,
      };

      // Create payment
      const result = await maintenanceFeeService.createPayment(buildingId, paymentData);

      // Handle success
      onPaymentAdded?.(result);
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error adding payment:", error);
      setError(error.message || "Error al crear el pago. Por favor, intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const renderBankDetails = (account) => {
    if (account.paymentMethod === "digital_wallet") {
      return `${account.bank} - ${account.phone_number}`;
    }
    return `${account.bank} - ${account.account}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: "24px" } }}
    >
      <DialogTitle>Añadir Pago</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* Apartment Selection */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Departamento</InputLabel>
            <Select
              value={apartmentId}
              label="Departamento"
              onChange={(e) => setApartmentId(e.target.value)}
              required
            >
              {apartments.map((apt) => (
                <MenuItem key={apt.id} value={apt.id}>
                  {apt.apartmentNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Bank Account Selection */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Método de Pago</InputLabel>
            <Select
              value={selectedBank}
              label="Método de Pago"
              onChange={(e) => setSelectedBank(e.target.value)}
              required
              disabled={loadingAccounts || bankAccounts.length === 0}
            >
              {loadingAccounts ? (
                <MenuItem disabled>Cargando métodos de pago...</MenuItem>
              ) : bankAccounts.length === 0 ? (
                <MenuItem disabled>No hay métodos de pago configurados</MenuItem>
              ) : (
                bankAccounts.map((account) => (
                  <MenuItem
                    key={account.bank}
                    value={account.bank}
                    sx={{
                      "& .MuiBox-root": {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box>
                      <Typography variant="body1">{renderBankDetails(account)}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {account.currency}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          {/* Reference Number */}
          <TextField
            fullWidth
            label="Código de Operación"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
            sx={{ mt: 2 }}
            required
          />

          {/* Amount */}
          <TextField
            fullWidth
            label="Monto (S/)"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mt: 2 }}
            required
          />

          {/* Notes */}
          <TextField
            fullWidth
            label="Concepto"
            multiline
            rows={2}
            value={concept}
            onChange={(e) => setConcept(e.target.value)}
            sx={{ mt: 2 }}
          />

          {/* File Upload */}
          <Box sx={{ mt: 2 }}>
            <MediaUpload
              files={files}
              onChange={setFiles}
              disabled={loading}
              hideAttachButton={false}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            loading ||
            !apartmentId ||
            !amount ||
            !referenceNumber ||
            !selectedBank ||
            loadingAccounts
          }
        >
          {loading ? "Procesando..." : "Añadir Pago"}
        </Button>
      </DialogActions>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddPaymentModal;
