import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddAccountModal from "../components/config/AddAccountModal";
import { maintenanceFeeConfigService } from "../services/maintenanceFeeConfigService";
import useGlobalStore from "../../../state/store";
import { useNavigate } from "react-router-dom";

const DAYS_OF_MONTH = Array.from({ length: 31 }, (_, i) => i + 1);

const MaintenanceFeeConfig = () => {
  const navigate = useNavigate();
  const buildingId = useGlobalStore((s) => s.buildingId);

  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddAccount, setShowAddAccount] = useState(false);

  useEffect(() => {
    if (!buildingId) return;
    (async () => {
      try {
        setLoading(true);
        const data = await maintenanceFeeConfigService.fetchBuildingFeeSettings(buildingId);
        setSettings(data || {});
      } catch (err) {
        console.error("Error fetching building fee settings:", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [buildingId]);

  if (!buildingId) {
    return <Typography sx={{ p: 2 }}>No building selected.</Typography>;
  }

  if (loading) {
    return <Typography sx={{ p: 2 }}>Loading...</Typography>;
  }

  const bankAccounts = settings.bank_accounts || [];

  const handleDueDayChange = (e) => {
    setSettings((prev) => ({
      ...prev,
      fee_due_day: e.target.value,
    }));
  };

  const handleAddAccount = (newAccount) => {
    const updated = [...bankAccounts, newAccount];
    setSettings((prev) => ({
      ...prev,
      bank_accounts: updated,
    }));
  };

  const handleDeleteAccount = (index) => {
    const updated = [...bankAccounts];
    updated.splice(index, 1);
    setSettings((prev) => ({
      ...prev,
      bank_accounts: updated,
    }));
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        fee_due_day: settings.fee_due_day || 5,
        bank_accounts: settings.bank_accounts || [],
      };
      await maintenanceFeeConfigService.upsertBuildingFeeSettings(buildingId, updatedData);
      alert("Configuración guardada");
    } catch (error) {
      console.error("Error saving config:", error);
      alert("Error saving config. Check console.");
    }
  };

  const handleClose = () => {
    navigate(-1); // go back one page
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        p: 3,
        maxWidth: 800,
        margin: "0 auto",
        position: "relative",
      }}
    >
      {/* Header with Title + Close Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Configuraciones de Cuotas
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "#6B7280" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Fecha de Vencimiento */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Fecha de Vencimiento
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Define la fecha límite de pago para las cuotas de mantenimiento. Esta fecha será aplicada
          automáticamente a las cuotas de los próximos meses
        </Typography>

        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Día</InputLabel>
          <Select
            value={settings.fee_due_day || 5}
            label="Día"
            onChange={handleDueDayChange}
            sx={{ backgroundColor: "white" }}
          >
            {DAYS_OF_MONTH.map((day) => (
              <MenuItem key={day} value={day}>
                {`Día ${day}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Cuenta Bancaria */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Cuenta Bancaria
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Indica las cuentas bancarias en las que los residentes realizarán los pagos
        </Typography>

        {/* Existing Accounts */}
        {bankAccounts.length === 0 ? (
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            No hay cuentas bancarias registradas.
          </Typography>
        ) : (
          bankAccounts.map((acc, idx) => (
            <Box
              key={idx}
              sx={{
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                p: 2,
                mb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {acc.bank || acc.payment_method}
                </Typography>
                {acc.type && <Typography variant="body2">Cuenta: {acc.type}</Typography>}
                {acc.owner && <Typography variant="body2">Titular: {acc.owner}</Typography>}
                {acc.account && (
                  <Typography variant="body2">Nro de Cuenta: {acc.account}</Typography>
                )}
                {acc.cci && <Typography variant="body2">Cód. Interbancario: {acc.cci}</Typography>}
              </Box>
              <Button
                color="error"
                onClick={() => handleDeleteAccount(idx)}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  borderRadius: "24px",
                }}
              >
                Eliminar
              </Button>
            </Box>
          ))
        )}

        {/* Button to Add Account */}
        <Button
          variant="contained"
          onClick={() => setShowAddAccount(true)}
          sx={{
            textTransform: "none",
            borderRadius: "24px",
          }}
        >
          Agregar cuenta
        </Button>
      </Box>

      {/* Recordatorios */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Recordatorios
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Activa y personaliza los recordatorios automáticos que el sistema enviará a los residentes
          en WhatsApp. Puedes configurar la frecuencia
        </Typography>

        <Box sx={{ display: "flex", gap: 4 }}>
          {/* Antes del Vencimiento */}
          <Box>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>Antes del vencimiento</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <input type="checkbox" id="reminder1" disabled />
                <label htmlFor="reminder1"> Avisar 1 día antes del vencimiento</label>
              </Box>
              <Box>
                <input type="checkbox" id="reminder3" disabled defaultChecked />
                <label htmlFor="reminder3"> Avisar 3 días antes del vencimiento</label>
              </Box>
              <Box>
                <input type="checkbox" id="reminder7" disabled defaultChecked />
                <label htmlFor="reminder7"> Avisar 7 días antes del vencimiento</label>
              </Box>
              <Box>
                <input type="checkbox" id="reminder14" disabled />
                <label htmlFor="reminder14"> Avisar 14 días antes del vencimiento</label>
              </Box>
            </Box>
          </Box>

          {/* Posterior al Vencimiento */}
          <Box>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>Posterior al vencimiento</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <input type="checkbox" id="reminderPost1" disabled defaultChecked />
                <label htmlFor="reminderPost1"> Avisar 1 día después</label>
              </Box>
              <Box>
                <input type="checkbox" id="reminderPost3" disabled />
                <label htmlFor="reminderPost3"> Avisar 3 días después</label>
              </Box>
              <Box>
                <input type="checkbox" id="reminderAllDays" disabled />
                <label htmlFor="reminderAllDays"> Avisar todos los días</label>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Guardar Cambios */}
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{
            textTransform: "none",
            borderRadius: "24px",
            px: 4,
          }}
        >
          Guardar Configuraciones
        </Button>
      </Box>

      {/* Modal to add bank account */}
      {showAddAccount && (
        <AddAccountModal
          open={showAddAccount}
          onClose={() => setShowAddAccount(false)}
          onAddAccount={handleAddAccount}
        />
      )}
    </Box>
  );
};

export default MaintenanceFeeConfig;
