import { create } from "zustand";
import { authService } from "../features/auth/services/authService";
import { Session } from "@supabase/auth-js";
import { Visit } from "../features/invitation/types";
import { RequestData } from "../shared/types";

import { Incident } from "../features/incidents/types";
import hasKeys from "../shared/utils/hasKey";
import { isValidNonEmptyArray } from "../shared/utils/isValidNonEmptyArray";
import { MaintenanceFee } from "../features/maintenanceFee/types";
import { User } from "../features/account/types";
import getDefaultPeriod from "../shared/utils/getDefaultPeriod";
import { WaterConsumption } from "../features/meters/types";

type Filters = {
  maintenanceFeesPeriod: string;
  waterConsumptionPeriod: string;
};
export type State = {
  user: User | null;
  loading: boolean;
  error: any;
  session: Session | null;
  setSession: (session: Session | null) => void;
  visits: RequestData<Visit[]>;
  setVisits: (visits: Partial<RequestData<Visit[]>>) => void;
  incidents: RequestData<Incident[]>;
  setIncidents: (incidents: Partial<RequestData<Incident[]>>) => void;
  maintenanceFees: RequestData<MaintenanceFee[]>;
  setMaintenanceFees: (fees: Partial<RequestData<MaintenanceFee[]>>) => void;
  setUser: (user: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;

  fileData: {
    depto: string;
    residente: string;
    monto: number;
    fecha: string;
    id?: string;
  }[];
  setFileData: (
    data: { depto: string; residente: string; monto: number; fecha: string }[]
  ) => void;

  invitationToken: string | null;
  buildingId: string | null;
  apartmentId: string | null;
  setInvitationToken: (token: string | null) => void;
  setBuildingId: (id: string | null) => void;
  setApartmentId: (id: string | null) => void;

  // Add apartments to store
  apartments: any[];
  setApartments: (apartments: any[]) => void;

  /** Water consumption */
  waterConsumptions: RequestData<WaterConsumption[]>;
  setWaterConsumptions: (
    waterConsumptions: Partial<RequestData<WaterConsumption[]>>
  ) => void;

  // Acción opcional para setear un edificio por defecto
  setDefaultBuildingId?: () => void;

  fetchUserProfile: (authUser: any) => Promise<void>;
  initializeUser: () => Promise<void>;
  clearContext: () => void;

  /** Filters */

  filters: Filters;
  setFilters: (filter: Partial<Filters>) => void;
};

export const useGlobalStore = create<State>((set, get) => ({
  //Session
  session: null,
  setSession: (session: Session | null) => set({ session }),
  // ---------------------
  //   ESTADO INICIAL
  // ---------------------
  user: null,
  loading: true,
  error: null,

  fileData: [],

  invitationToken: null,
  buildingId: null,
  apartmentId: null,
  apartments: [],

  setUser: (user: any) => {
    set({ user });

    if (user?.buildings?.length === 1) {
      set({ buildingId: user.buildings[0].id });
    }
  },
  setLoading: (loading: boolean) => set({ loading }),
  setError: (error: any) => set({ error }),

  setApartments: (apartments: any[]) => set({ apartments }),

  setDefaultBuildingId: () => {
    const user = get().user;
    if (user?.buildings?.length > 0) {
      set({ buildingId: user.buildings[0].id });
    } else {
      console.warn("No buildings found for the user");
      set({ buildingId: null });
    }
  },

  async fetchUserProfile(authUser: any) {
    if (!authUser) {
      set({ user: null, loading: false });
      return;
    }

    try {
      const userProfile = await authService.getUserProfile(authUser.id);

      const buildings = userProfile.is_worker
        ? await authService.getUserBuildings(userProfile.id)
        : [];

      const mergedUser = { ...authUser, ...userProfile, buildings };
      get().setUser(mergedUser);

      if (hasKeys(mergedUser, ["buildings", "building"])) {
        if (isValidNonEmptyArray(mergedUser.buildings)) {
          const defaultBuilding = mergedUser.buildings[0];

          set({ buildingId: defaultBuilding.id });

          if (isValidNonEmptyArray(defaultBuilding.apartments)) {
            set({ apartmentId: defaultBuilding.apartments[0]?.id });
          }
        }
      }

      set({ loading: false });
    } catch (error) {
      console.error("Error fetching user profile:", error);
      set({ error, loading: false });
    }
  },

  async initializeUser() {
    const session = get().session;

    if (session) {
      await get().fetchUserProfile(session.user);
    } else {
      set({ user: null, loading: false });
    }
  },

  setInvitationToken: (token: string | null) => set({ invitationToken: token }),
  setBuildingId: (id: string | null) => set({ buildingId: id }),
  setApartmentId: (id: string | null) => set({ apartmentId: id }),

  visits: {
    error: null,
    data: [],
    loading: false,
  },
  setVisits: (visits) => set({ visits: { ...get().visits, ...visits } }),

  incidents: {
    error: null,
    data: [],
    loading: false,
  },
  setIncidents: (incidents) =>
    set({ incidents: { ...get().incidents, ...incidents } }),

  maintenanceFees: {
    error: null,
    data: [],
    loading: false,
  },
  setMaintenanceFees: (fees) =>
    set({
      maintenanceFees: { ...get().maintenanceFees, ...fees },
    }),

  waterConsumptions: { data: [], error: null, loading: false },
  setWaterConsumptions: (waterConsumptions) =>
    set({
      waterConsumptions: { ...get().waterConsumptions, ...waterConsumptions },
    }),

  clearContext: () =>
    set({
      invitationToken: null,
      buildingId: null,
      apartmentId: null,
      apartments: [],
    }),

  setFileData: (data) => set({ fileData: data }),

  filters: {
    maintenanceFeesPeriod: getDefaultPeriod(),
    waterConsumptionPeriod: getDefaultPeriod(),
  },
  setFilters: (filters) => set({ filters: { ...get().filters, ...filters } }),
}));

export default useGlobalStore;
