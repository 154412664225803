import { Paper, Box, Stack, Typography, Grid, Skeleton } from "@mui/material";

const IncidentDetailViewSkeleton = () => (
  <Grid container spacing={3}>
    {/* Left Column */}
    <Grid item xs={12} md={4}>
      <Stack spacing={3}>
        {/* Details Card */}
        <Paper sx={{ p: 3, borderRadius: 2 }}>
          <Skeleton width={"40%"} height={24} variant="text" sx={{ mb: 2 }} />
          <Stack spacing={3}>
            <Box>
              <Box sx={{ mb: 1 }}>
                <Skeleton width={"60%"} height={24} variant="text" />
                <Skeleton width={"60%"} height={18} variant="text" />
              </Box>
            </Box>

            <Box>
              <Box sx={{ mb: 1 }}>
                <Skeleton width={"60%"} height={24} variant="text" />
                <Skeleton width={"70%"} height={18} variant="text" />
              </Box>
            </Box>

            {/* Add Category */}
            <Box>
              <Skeleton width={"50%"} height={24} variant="text" />
              <Skeleton width={"60%"} height={18} variant="text" />
            </Box>

            {/* Add Visibility */}
            <Box>
              <Skeleton width={"40%"} height={24} variant="text" />
              <Skeleton width={"50%"} height={18} variant="text" />
            </Box>

            <Box>
              <Skeleton width={"80%"} height={24} variant="text" />
              <Skeleton width={"70%"} height={18} variant="text" />
            </Box>

            <Box>
              <Skeleton width={"55%"} height={24} variant="text" />
              <Skeleton width={"70%"} height={18} variant="text" />
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </Grid>

    {/* Right Column */}
    <Grid item xs={12} md={8}>
      <Stack spacing={3}>
        {/* Description Card */}
        <Box sx={{ p: 3 }}>
          <Skeleton width="25%" height={24} variant="text" sx={{ mb: 2 }} />

          <Skeleton width={"80%"} height={18} variant="text" />
          <Skeleton width={"70%"} height={18} variant="text" />
          <Skeleton width={"82%"} height={18} variant="text" />
          <Skeleton width={"65%"} height={18} variant="text" />
        </Box>

        {/* Comments Section */}
        <Box sx={{ p: 3 }}>
          <Skeleton width="25%" height={24} variant="text" sx={{ mb: 2 }} />
          <Skeleton width={"100%"} height={56} variant="rounded" />
        </Box>
      </Stack>
    </Grid>
  </Grid>
);

export default IncidentDetailViewSkeleton;
