import { useRef, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddPaymentModal from "../maintenanceFeeDashboard/AddPaymentModal";
import { Groups, PersonOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddIndividualChargeModal from "../maintenanceFeeDashboard/AddIndividualChargeModal";

const MaintenanceFeeTable = ({
  onSearchChange,
  filterValue,
  onFilterChange,
  apartments = [], // or pass them via props
  buildingId, // building ID
  onPaymentAdded, // callback to refresh data, if desired
  userId,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  // State to show/hide modals
  const [openAddPayment, setOpenAddPayment] = useState<boolean>(false);
  const [openAddIndividualCharge, setOpenAddIndividualCharge] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpenPaymentModal = () => {
    setOpenAddPayment(true);
  };

  const handleClosePaymentModal = () => {
    setOpenAddPayment(false);
  };

  const handleOpenIndividualChargeModal = () => {
    setOpenAddIndividualCharge(true);
  };

  const handleCloseIndividualChargeModal = () => {
    setOpenAddIndividualCharge(false);
  };

  const handlePaymentAdded = async (payment) => {
    try {
      // Refresh the data
      onPaymentAdded?.();
      // Close the modal
      handleClosePaymentModal();
    } catch (error) {
      console.error("Error handling payment addition:", error);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLButtonElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {/* Existing Search & Filter Toolbar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
          gap: 2,
        }}
      >
        {/* Search + Filter */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            placeholder="Buscar..."
            onChange={(e) => onSearchChange(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "#9CA3AF", marginRight: 1 }} />
              ),
            }}
            sx={{
              width: "200px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                fontSize: "14px",
                backgroundColor: "#F9FAFB",
              },
            }}
          />
          <FormControl size="small" sx={{ minWidth: 140 }}>
            <InputLabel sx={{ fontSize: "14px", color: "#6B7280" }}>
              Filtro
            </InputLabel>
            <Select
              value={filterValue}
              onChange={(e) => onFilterChange(e.target.value)}
              sx={{
                borderRadius: "24px",
                fontSize: "14px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Validados">Validados</MenuItem>
              <MenuItem value="Pendientes">Pendientes</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            sx={{
              color: "#4F46E5",
              borderColor: "#E0E7FF",
              backgroundColor: "#F3F4F6",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#E0E7FF",
                borderColor: "#4F46E5",
              },
            }}
          >
            Exportar
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(90deg, #F97316 0%, #9333EA 100%)",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { opacity: 0.9 },
            }}
          >
            Conciliación Automática
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#315CAC",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#2A4F94" },
            }}
            onClick={handleToggle}
            ref={anchorRef}
          >
            Añadir cargo
          </Button>
          <Popper
            sx={{
              zIndex: 1,
              borderRadius: "0px",
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  sx={{
                    borderRadius: "16px",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem
                        key={"Individual"}
                        onClick={handleOpenIndividualChargeModal}
                        sx={{
                          gap: "8px",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#E0E7FF",
                            borderRadius: "16px",
                          },
                        }}
                      >
                        <PersonOutline /> Individual
                      </MenuItem>
                      <MenuItem
                        key={"Multiple"}
                        onClick={() => navigate("/maintenance-fee/import")}
                        sx={{
                          gap: "8px",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#E0E7FF",
                            borderRadius: "16px",
                          },
                        }}
                      >
                        <Groups />
                        Múltiple
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#315CAC",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#2A4F94" },
            }}
            onClick={handleOpenPaymentModal}
          >
            Añadir Pago
          </Button>
        </Box>
      </Box>

      {/* AddPaymentModal */}
      {openAddPayment && (
        <AddPaymentModal
          open={openAddPayment}
          onClose={handleClosePaymentModal}
          apartments={apartments}
          buildingId={buildingId}
          onPaymentAdded={handlePaymentAdded}
        />
      )}

      {/* AddIndividualChargeModal */}
      {openAddIndividualCharge && (
        <AddIndividualChargeModal
          open={openAddIndividualCharge}
          onClose={handleCloseIndividualChargeModal}
          apartments={apartments}
          buildingId={buildingId}
          userId={userId}
        />
      )}
    </>
  );
};

const TableRowSkeleton = () =>
  columns.map((column) => (
    <TableCell
      key={column.dataKey}
      align={column.numeric || false ? "right" : "left"}
    >
      <Skeleton width={140} height={18} />
    </TableCell>
  ));

export default MaintenanceFeeTable;
