
import useIncidents from "../../features/incidents/hooks/useIncidents";
import { useVisits } from "../../features/invitation/hooks/useVisits";
import { useWaterConsumptions } from "../../features/meters/hooks/useWaterConsumptions";
import { useGlobalStore } from "../../state/store";
import { useMaintenanceFee } from "../../features/maintenanceFee/hooks/useMaintenanceFees";

function useGetBuildingData() {
 
  const {getVisits, hasCachedVisits} = useVisits();
  const { filters } = useGlobalStore();
  const { waterConsumptionPeriod } = filters;
  
  const { getIncidents, hasCachedIncidents } = useIncidents(); 
  const { getWaterConsumptions, hasCachedWaterConsumptions } = useWaterConsumptions();
  const { fetchMaintenanceData, hasCachedMaintenanceFees } = useMaintenanceFee();

  const getBuildingAssociatedData = async ({ buildingId }: { buildingId: string }) => {
    if (buildingId) {
      Promise.all([
        getWaterConsumptions(waterConsumptionPeriod, buildingId),
        getVisits({ buildingId }),
        getIncidents({ buildingId }),
        fetchMaintenanceData({ buildingId, period: filters.maintenanceFeesPeriod })
      ]);
    }
  }

 
  const hasAllBuildingDataCached = [
    hasCachedIncidents,
   hasCachedVisits,
    hasCachedWaterConsumptions,
    hasCachedMaintenanceFees
  ].every(Boolean);

  return { getBuildingAssociatedData, hasAllBuildingDataCached };
}

export default useGetBuildingData;
