import { useState } from "react";
import useGlobalStore from "../../../state/store";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import hasKeys from "../../../shared/utils/hasKey";

import useSession from "../../auth/hooks/useSession";

function useUser() {
  const user = useGlobalStore((state) => state.user);
  const setUser = useGlobalStore((state) => state.setUser);
  const initializeUser = useGlobalStore((state) => state.initializeUser);
  const { setSession } = useSession();

  const [synchingUser, setSynchingUser] = useState(false);

  const requiredUserFields = ["buildings"];

  const getUserProfile = async () => {
    if (!isValidNonEmptyObject(user) || !hasKeys(user, requiredUserFields)) {
      try {
        setSynchingUser(true);

        await initializeUser();
        setSynchingUser(false);
      } catch (error) {
        console.error("Fetch user profile error:", error);
        setSynchingUser(false);
      }
    }
  };

  const purgeUser = () => {
    setSession(null);
    setUser(null);
  };

  return {
    user,
    setUser,
    initializeUser,
    getUserProfile,
    synchingUser,
    purgeUser,
    requiredUserFields,
  };
}

export default useUser;
