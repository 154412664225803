import { useEffect, useState } from "react";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import useUser from "../../account/hooks/useUser";
import { incidentService } from "../services/incidentService";
import { useIncident } from "./useIncident";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";

export default function useIncidents() {
  const setIncidents = useGlobalStore((state) => state.setIncidents);
  const [error, setError] = useState<string | null>(null);
  const incidents = useGlobalStore((state) => state.incidents);
  const hasCachedIncidents = isValidNonEmptyArray(incidents.data);

  const { updateIncidentStatus: updateIncidentStatusBase } = useIncident();
  const { user } = useUser();

 
  const getIncidents = async ({ buildingId }: { buildingId: string }) => {
    if (!buildingId || !isValidNonEmptyObject(user)) return;

    try {
      setIncidents({ data: [], loading: true, error: null });

      const incidentResponses = await Promise.all([
        incidentService.fetchUserIncidents(user!.id),
        incidentService.fetchCommunityIncidents(buildingId, user!.id),
      ]);

      const incidents = incidentResponses
        .flat()
        .filter((incident) => incident.building_id === buildingId);

      setIncidents({ data: incidents, loading: false, error: null });
    } catch (err: any) {
      console.error("Error fetching incidents:", err);
      setError(err.message || "Error loading incidents");

      setIncidents({
        data: incidents.data,
        loading: false,
        error: "Error loading incidents",
      });
    }
  };

  const fetchBuildingIncidents = async ({
    buildingId,
  }: {
    buildingId: string;
  }) => {
    if (!buildingId) {
      setIncidents({ error: "Building ID is required" });

      return;
    }

    setIncidents({ error: null, loading: true });

    try {
      const response = await incidentService.fetchBuildingIncidents(
        buildingId,
        user!.id
      );

      setIncidents({
        data: isValidNonEmptyArray(response) ? response : [],
      });
      return response;
    } catch (err) {
      console.error("Error fetching incidents:", err); // Add error logging
      const errorMessage = err.message || "Error al cargar las incidencias";

      setIncidents({ error: errorMessage });
    } finally {
      setIncidents({ loading: false });
    }
  };

  const updateIncidentStatus = async ({
    incidentId,
    status,
    buildingId,
  }: {
    incidentId: string;
    status: string;
    buildingId: string;
  }) => {
    if (incidentId && buildingId && status) {
      try {
        await updateIncidentStatusBase(incidentId, status);
        await getIncidents({ buildingId });
      } catch (err: any) {
        setError(err.message || "Error updating incident status");
      }
    }
  };

  return {
    fetchBuildingIncidents,
    loading: incidents.loading,
    error: incidents.error,
    incidents: incidents.data ?? [],
    setIncidents,
    hasCachedIncidents,
    getIncidents,
    updateIncidentStatus,
  };
}
