export function isValidNonEmptyArray(value: unknown) {
  if (!Array.isArray(value)) {
    return false;
  }

  if (value.length === 0) {
    return false;
  }

  return true;
}
