
//src/supabase/services/realtimeService.ts
import { RealtimeChannel } from '@supabase/supabase-js';
import { supabase } from '../client';
import { RealtimeChannelManager, ChannelConfig } from './realtimeChannelManager';

class RealtimeService {
  private channelManager: RealtimeChannelManager;
  private isConnected: boolean;
  private connectionRetries: number;
  private readonly maxRetries: number = 3;
  private readonly retryDelay: number = 2000;
  private isInitialized: boolean;

  constructor() {
    this.channelManager = new RealtimeChannelManager();
    this.isConnected = false;
    this.connectionRetries = 0;
    this.isInitialized = false;
  }

  async init() {
    if (this.isInitialized) return;
    
    try {
      await this.connect();
      this.isInitialized = true;

      supabase.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_IN') {
          this.connect();
        } else if (event === 'SIGNED_OUT') {
          this.disconnect();
        }
      });

    } catch (error) {
      console.error('Failed to initialize realtime service:', error);
    }
  }

  async connect() {
    if (this.isConnected) return;

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.log('No active session, skipping realtime connection');
        return;
      }

      this.isConnected = true;
      this.connectionRetries = 0;
    } catch (error) {
      console.error('Realtime connection error:', error);
      await this.handleConnectionError();
    }
  }

  async disconnect() {
    try {
      await this.channelManager.cleanup();
      this.isConnected = false;
    } catch (error) {
      console.error('Error disconnecting realtime:', error);
    }
  }

  async subscribeToChannel(channelName: string, config?: ChannelConfig): Promise<RealtimeChannel> {
    if (!this.isConnected) {
      await this.connect();
    }

    try {
      const existingChannel = this.channelManager.getChannel(channelName);
      if (existingChannel && this.channelManager.isSubscribed(channelName)) {
        return existingChannel;
      }

      await this.channelManager.removeChannel(channelName);

      const channel = supabase.channel(channelName, {
        config: {
          broadcast: { self: true }
        }
      });
      
      if (config?.event) {
        channel.on(
          'postgres_changes',
          { event: '*', schema: 'public' },
          (payload) => {
            console.debug(`Message received on ${channelName}:`, payload);
            config.callback?.(payload);
          }
        );
      }

      await channel.subscribe((status: string) => {
        if (status === 'SUBSCRIBED') {
          this.channelManager.setChannel(channelName, channel, config);
        }
      });

      return channel;
    } catch (error) {
      console.error(`Error subscribing to channel ${channelName}:`, error);
      throw error;
    }
  }

  private async handleConnectionError() {
    if (this.connectionRetries < this.maxRetries) {
      this.connectionRetries++;
      const delay = this.retryDelay * Math.pow(2, this.connectionRetries - 1);
      await new Promise(resolve => setTimeout(resolve, delay));
      await this.connect();
    } else {
      console.error('Max connection retries reached');
      this.isConnected = false;
    }
  }

  getChannel(channelName: string): RealtimeChannel | undefined {
    return this.channelManager.getChannel(channelName);
  }
}

export const realtimeService = new RealtimeService();