export function getRandomNumber(min: number = 0, max: number = 100) {
  // Make sure min and max are numbers
  min = Number(min);
  max = Number(max);

  // Swap values if min is greater than max
  if (min > max) {
    [min, max] = [max, min];
  }

  // Get a random number between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1) + min);
}
