// src/features/incident/constants.js
export const INCIDENT_CATEGORIES = {
    MAINTENANCE: 'maintenance',
    SECURITY: 'security',
    NOISE: 'noise',
    CLEANING: 'cleaning',
    COMMON_AREAS: 'common_areas',
    ELEVATOR: 'elevator',
    PARKING: 'parking',
    UTILITIES: 'utilities',
    OTHER: 'other'
};

export const INCIDENT_STATUS = {
    NEW: 'new',
    ASSIGNED: 'assigned',
    IN_PROGRESS: 'in_progress',
    PENDING_REVIEW: 'pending_review',
    RESOLVED: 'resolved',
    CLOSED: 'closed'
};

export const INCIDENT_PRIORITY = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
    CRITICAL: 'critical'
};

// SLA times in hours
export const SLA_TIMES = {
    critical: 4,
    high: 24,
    medium: 72,
    low: 168
};

export const STATUS_CONFIG = {
  new: { label: 'Nuevo', color: 'info', icon: 'AlertCircle' },
  assigned: { label: 'Asignado', color: 'secondary', icon: 'UserCheck' },
  in_progress: { label: 'En proceso', color: 'primary', icon: 'RefreshCw' },
  pending_review: { label: 'Pendiente revisión', color: 'warning', icon: 'Clock' },
  resolved: { label: 'Resuelto', color: 'success', icon: 'CheckCircle2' },
  closed: { label: 'Cerrado', color: 'default', icon: 'Archive' }
};

export const PRIORITY_CONFIG: { [key: string]: { label: string; color: string; icon: string; sla: number } } = {
  low: { label: 'Baja', color: 'default', icon: 'ArrowDown', sla: 168 },
  medium: { label: 'Media', color: 'info', icon: 'ArrowRight', sla: 72 },
  high: { label: 'Alta', color: 'warning', icon: 'ArrowUp', sla: 24 },
  critical: { label: 'Crítica', color: 'error', icon: 'AlertTriangle', sla: 4 }
};

export const ALLOWED_TRANSITIONS = {
  new: ['in_progress', 'assigned'],
  assigned: ['in_progress'],
  in_progress: ['pending_review', 'resolved'],
  pending_review: ['resolved'],
  resolved: ['closed'],
  closed: []
};

export const INCIDENT_VISIBILITY = {
  PUBLIC: 'public',
  COMMUNITY: 'community',
  PRIVATE: 'private'
};


export const VISIBILITY_LABELS: { [key: string]: string } = {
  public: 'Público',
  community: 'Comunitario',
  private: 'Privado'
};

export const VISIBILITY_DESCRIPTIONS = {
  public: 'Visible para todos los residentes y administradores',
  community: 'Solo visible para miembros de la comunidad',
  private: 'Solo visible para administración y reportante'
};

export const CATEGORY_LABELS: { [key: string]: string } = {
  maintenance: 'Mantenimiento',
  security: 'Seguridad',
  noise: 'Ruido',
  cleaning: 'Limpieza',
  common_areas: 'Áreas Comunes',
  elevator: 'Ascensor',
  parking: 'Estacionamiento',
  utilities: 'Servicios',
  other: 'Otros'
};