// src/features/invitation/services/invitationService.js

import { secureApi } from "../../../supabase/secureApi";

export const invitationService = {

  fetchVisitsByBuilding: async (buildingId) => {
    if (!buildingId) {
      throw new Error("Building ID is required");
    }
  
    try {
      const visits = await secureApi.rpc("get_visits_by_building", {
        input_building_id: buildingId,
      });

      const uniqueVisits = visits.filter(
        (visit, index, self) =>
          index === self.findIndex((v) => v.visit_id === visit.visit_id)
      ).map((visit) => ({
        ...visit,
        visitor_first_name:
          visit.visitor_first_name || visit.resident_first_name || "Visitante",
        visitor_last_name:
          visit.visitor_last_name || visit.resident_last_name || "",
      }));

    
  
      return uniqueVisits;
    } catch (error) {
      console.error("Error fetching visits by building ID:", error);
      throw error;
    }
  },

  
  fetchVisitsForConserje: async (userId) => {
    try {
      if (!userId || typeof userId !== "string") {
        throw new Error("Invalid user ID");
      }

      console.log("start loading...");

      const workerBuildings = await secureApi.get("worker_buildings", {
        select: "building_id",
        filter: { worker_id: userId, is_active: true },
      });

      console.log("end loading...");

      if (!workerBuildings || workerBuildings.length === 0) {
        console.warn("No buildings assigned to this conserje");
        return [];
      }

      const allVisits = [];
      const visitIds = new Set(); // To keep track of unique visit IDs

      for (const building of workerBuildings) {
        const visits = await secureApi.rpc("get_visits_by_building", {
          input_building_id: building.building_id,
        });

        // Filter out duplicate visits
        const uniqueVisits = visits.filter((visit) => {
          if (!visitIds.has(visit.visit_id)) {
            visitIds.add(visit.visit_id);
            return true;
          }
          return false;
        });

        allVisits.push(...uniqueVisits);
      }

      return allVisits;
    } catch (error) {
      console.error("Error fetching visits for conserje:", error);
      throw error;
    }
  },



  checkInVisitor: async (visitId) => {
    if (!visitId) {
      throw new Error("Visit ID is required");
    }

    try {
      const data = await secureApi.rpc("check_in_visitor", {
        input_visit_id: visitId,
      });
      return data;
    } catch (error) {
      console.error("Error checking in visitor:", error);
      throw error;
    }
  },

  fetchVisitDetails: async (visitId, userId) => {
    if (!visitId || !userId) {
      throw new Error("Visit ID and User ID are required");
    }

    try {
      const visits = await secureApi.rpc("get_visit_details", {
        input_visit_id: visitId,
      });

      if (!Array.isArray(visits) || visits.length === 0) {
        return null;
      }

      return visits[0];
    } catch (error) {
      console.error("Error fetching visit details:", error);
      throw error;
    }
  },
};
