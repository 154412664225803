// src/features/manualVisitRegistration/hooks/useBuilding.js

import { useState, useEffect } from "react";
import { manualVisitRegistrationService } from "../services/manualVisitRegistrationService";
import useGlobalStore from "../../../state/store";

export const useBuilding = () => {
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useGlobalStore((state) => state.user);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId); // Si tienes una función para actualizar el buildingId en el estado global

  useEffect(() => {
    const fetchApartments = async () => {
      if (!user || !user.id) {
        setError("No se pudo determinar el usuario actual.");
        setLoading(false);
        return;
      }

      // Log para verificar que tenemos al usuario
      console.log("Usuario actual:", user);

      // Si el usuario es administrador, no hacemos la llamada a `getWorkerBuildings`
      if (user.isAdmin) {
        console.log("El usuario es administrador, no se filtrará por edificio.");
        setLoading(false);
        return; // El admin puede ver todas las incidencias sin filtrar por `buildingId`
      }

      console.log("Realizando búsqueda de edificios para el usuario:", user.id);

      try {
        const workerBuildings = await manualVisitRegistrationService.getWorkerBuildings(user.id);

        // Log para comprobar los edificios asignados
        console.log("Edificios asignados al usuario:", workerBuildings);

        if (!workerBuildings || workerBuildings.length === 0) {
          setError("No hay edificios asignados a este conserje.");
          setLoading(false);
          return;
        }

        // Tomamos el primer edificio asignado
        const firstBuildingId = workerBuildings[0].building_id;
        console.log("Primer edificio asignado:", firstBuildingId);

        // Aquí actualizamos el estado global con el nuevo buildingId
        setBuildingId(firstBuildingId); // Actualizamos el estado global

        const apartmentsData = await manualVisitRegistrationService.fetchApartmentsForBuilding(firstBuildingId);
        setApartments(apartmentsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching apartments:", error);
        setError("Error al cargar los apartamentos. Por favor, intente de nuevo.");
        setLoading(false);
      }
    };

    fetchApartments();
  }, [user, setBuildingId]);

  // Log del buildingId para comprobar qué valor tiene
  console.log("buildingId desde el hook:", buildingId);

  return { apartments, loading, error, buildingId };
};
