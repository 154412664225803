import { useCallback } from 'react';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../analytics';

interface UseBuildingAnalytics {
  trackApartmentSelected: (apartmentId: string, buildingId: string, userId?: string) => void;
}

export const useBuildingAnalytics = (): UseBuildingAnalytics => {
  const { track } = useAnalytics();

  const trackApartmentSelected = useCallback(
    (apartmentId: string, buildingId: string, userId?: string): void => {
      track(EVENT_TYPES.APARTMENT_SELECTED, { apartmentId, buildingId, userId });
    },
    [track]
  );


  return {
    trackApartmentSelected,
  };
};
