import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import OpacityOutlined from "@mui/icons-material/OpacityOutlined";

import MeterTable, { MeterRow } from "../components/MeterTable";
import MeterDetailDialog from "../components/MeterDetailDialog";
import { useWaterConsumptions } from "../hooks/useWaterConsumptions";

import useFilters from "../../../shared/hooks/useFilters";
import { WaterConsumption } from "../types";
import exportWaterConsumptionsToExcel from "../utils/exportWaterConsumptionsToExcel";
import getMonthPeriodOptions from "../../../shared/utils/getMonthPeriodOptions";
import { useGlobalStore } from "../../../state/store";
import { useBuilding } from "../../../building/hooks/useBuilding";

const MeterMain = () => {
  const [consumptionTotal, setConsumptionTotal] = useState("");
  const [valorPagado, setValorPagado] = useState("");
  const [costPerM3, setCostPerM3] = useState(0);

  const [selectedMeter, setSelectedMeter] = useState<WaterConsumption | null>(
    null
  );
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const { filters, setFilters } = useFilters();
  const { buildingId } = useBuilding();


  const waterConsumptionPeriod = filters.waterConsumptionPeriod;

  const {
    waterConsumptions,
    loading,
    error,
    updateWaterConsumption,
    hasCachedWaterConsumptions,
    getWaterConsumptions,
  } = useWaterConsumptions();

  // Recalcular el costo por m³ cada vez que cambien consumo total o valor pagado
  useEffect(() => {
    const ct = parseFloat(consumptionTotal);
    const vp = parseFloat(valorPagado);

    if (!isNaN(ct) && !isNaN(vp) && ct > 0) {
      setCostPerM3(vp / ct);
    } else {
      setCostPerM3(0);
    }
  }, [consumptionTotal, valorPagado]);

  const handleDateChange = (waterConsumptionPeriod: string) => {
    setFilters({ waterConsumptionPeriod });

    if (waterConsumptionPeriod && buildingId) {
      getWaterConsumptions(waterConsumptionPeriod, buildingId);
    }
  };

  const handleSaveChanges = async (updatedMeter: MeterRow) => {
      const waterConsumption: WaterConsumption = {
        ...updatedMeter,
        consumption: updatedMeter.consumption ?? 0, 
      };
  
      if (buildingId) {
        await updateWaterConsumption({
          waterConsumption,
          waterConsumptionPeriod,
          buildingId,
        });
      }
  
      setIsDetailOpen(false);
    };

  const handleDetailOpen = (meter: MeterRow) => {
    setSelectedMeter({
      ...meter,
      consumption: meter.consumption ?? 0,
    });
    setIsDetailOpen(true);
  };

  useEffect(() => {
    if (!hasCachedWaterConsumptions && buildingId) {
      if (waterConsumptionPeriod) {
        getWaterConsumptions(waterConsumptionPeriod, buildingId);
      }
    }
  }, [buildingId, waterConsumptionPeriod]);

  const handleDetailClose = () => {
    setSelectedMeter(null);
    setIsDetailOpen(false);
  };

  const monthPeriods = getMonthPeriodOptions();

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Header: COntadores */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <OpacityOutlined sx={{ fontSize: 32, color: "#315CAC" }} />
        <Typography
          sx={{ fontSize: "20px", fontWeight: 600, color: "#315CAC" }}
        >
          Contadores
        </Typography>
      </Box>

      {/* SELECTOR DE period important :D */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
        <Select
          value={waterConsumptionPeriod}
          onChange={(e) => handleDateChange(e.target.value)}
          IconComponent={ArrowDropDownIcon}
          sx={{
            textAlign: "center",
            minWidth: 150,
            fontSize: "14px",
            fontWeight: 500,
            color: "#315CAC",
            border: "1px solid #D1D5DB",
            borderRadius: "24px",
            padding: "4px 12px",
            backgroundColor: "white",
            "& .MuiSelect-icon": {
              color: "#315CAC",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover": {
              backgroundColor: "#F3F4F6",
            },
          }}
        >
          {monthPeriods.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {/* Línea derecha */}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
      </Box>

      {/* SECCIÓN EDIFICIO: Consumo total, Valor pagado y Costo por m³ */}
      <Box mt={3}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Edificio
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
          <TextField
            label="Consumo total (m³)"
            variant="outlined"
            size="small"
            type="number"
            value={consumptionTotal}
            onChange={(e) => setConsumptionTotal(e.target.value)}
          />
          <TextField
            label="Valor pagado (S/)"
            variant="outlined"
            size="small"
            type="number"
            value={valorPagado}
            onChange={(e) => setValorPagado(e.target.value)}
          />
          <TextField
            label="Costo por m³"
            variant="outlined"
            size="small"
            type="number"
            value={costPerM3.toFixed(2)}
            InputProps={{ readOnly: true }}
          />
        </Box>
      </Box>

      {/* BOTÓN EXPORTAR */}
      <Box display="flex" justifyContent="end" alignItems="center" mt={3}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => exportWaterConsumptionsToExcel(waterConsumptions)}
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          Exportar
        </Button>
      </Box>

      <MeterTable
        onDetailOpen={handleDetailOpen}
        costPerM3={costPerM3}
        selectedDate={waterConsumptionPeriod}
        meters={waterConsumptions}
        loading={loading}
      />

      {/* DIALOGO DE DETALLES */}
      <MeterDetailDialog
        open={isDetailOpen}
        meter={selectedMeter}
        onClose={handleDetailClose}
        onSaveChanges={handleSaveChanges}
      />
    </Container>
  );
};

export default MeterMain;
