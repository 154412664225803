import { format, addMonths } from "date-fns";
import { es } from "date-fns/locale";

/** Get list of defined list of  months  from now in  past and future  */
const getMonthPeriodOptions = (numberOfMonths = 5) => {
  const options = [];
  const today = new Date();
  for (let i = -numberOfMonths; i <= numberOfMonths; i++) {
    const date = addMonths(today, i);
    options.push({
      value: format(date, "yyyy-MM"),
      label:
        format(date, "MMMM yyyy", { locale: es }).charAt(0).toUpperCase() +
        format(date, "MMMM yyyy", { locale: es }).slice(1),
    });
  }
  return options;
};

export default getMonthPeriodOptions;
