import { Box, Typography, Button, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { format } from "date-fns";

import { ROUTES } from "../../../../config/routes";
import { useBuilding } from "../../hooks/useBuilding";
import getMonthPeriodOptions from "../../../../shared/utils/getMonthPeriodOptions";

const MaintenanceFeeHeader = ({
  selectedDate,
  onDateChange
}) => {
  const navigate = useNavigate();
  

  const handleDateChange = (value) => {
    onDateChange(value);
  };

  const handleConfigClick = () => {
    navigate(ROUTES.MAINTENANCE_FEE_CONFIG);
  };

  const monthPeriods = getMonthPeriodOptions();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
  

      {/* Título y botón de configuraciones */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ReceiptIcon sx={{ fontSize: 28, color: "#315CAC" }} />
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#315CAC",
            }}
          >
            Cuota de Mantenimiento
          </Typography>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#315CAC",
            color: "white",
            borderRadius: "24px",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 500,
            padding: "6px 16px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            "&:hover": {
              backgroundColor: "#2A4F94",
            },
          }}
          onClick={handleConfigClick}
        >
          Configuraciones
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFFFFF",
              color: "#315CAC",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            3
          </Box>
        </Button>
      </Box>

      {/* Selector de meses */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
        <Select
          value={selectedDate || format(new Date(), "yyyy-MM")}
          onChange={(e) => handleDateChange(e.target.value)}
          IconComponent={ArrowDropDownIcon}
          sx={{
            textAlign: "center",
            minWidth: 180,
            maxWidth: 200,
            fontSize: "14px",
            fontWeight: 500,
            color: "#315CAC",
            border: "1px solid #D1D5DB",
            borderRadius: "24px",
            padding: "4px 12px",
            backgroundColor: "white",
            "& .MuiSelect-icon": {
              color: "#315CAC",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover": {
              backgroundColor: "#F3F4F6",
            },
          }}
        >
          {monthPeriods.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
      </Box>
    </Box>
  );
};

export default MaintenanceFeeHeader;
