export default function hasKeys(obj: object, keys: string[]) {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  for (const key of keys) {
    if (!obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
}
