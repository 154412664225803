// src/shared/layouts/MobileSidebar.tsx
import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import PremiumFeaturePopup from "../../features/dashboard/components/PremiumFeaturePopup";
import { sidebarItems } from "../../config/sidebarConfig";

export const DRAWER_WIDTH = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // This makes sure content is below the AppBar
  ...theme.mixins.toolbar,
}));

type MobileSidebarProps = {
  open: boolean;
  handleCloseSidebar: () => void;
};

const MobileSidebar = ({ open, handleCloseSidebar }: MobileSidebarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={handleCloseSidebar}
      ModalProps={{
        keepMounted: true, // Better performance on mobile.
      }}
      PaperProps={{
        sx: {
          width: DRAWER_WIDTH,
          border: "none",
          top: 0,
          height: "100%",
          boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DrawerHeader />
      <List>
        {sidebarItems.map((item, index) => (
          <MenuItemWrapper
            key={index}
            menu={item}
            handleCloseSidebar={handleCloseSidebar}
          >
            <ListItem
              disablePadding
              sx={{
                display: "block",
                bgcolor:
                  item.route === location.pathname ? "#E9EDFF" : "inherit",
                color:
                  item.route === location.pathname
                    ? "#315CAC"
                    : theme.palette.text.primary,
                "&.Mui-disabled": {
                  opacity: 0.5,
                },
              }}
              onClick={() => {
                if (item.premium) {
                  setPopupOpen(true);
                } else {
                  handleCloseSidebar();
                }
              }}
            >
              <ListItemButton sx={{ minHeight: 48, justifyContent: "initial" }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    color:
                      item.route === location.pathname
                        ? "#315CAC"
                        : theme.palette.text.primary,
                    mr: 3,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ opacity: 1 }} />
              </ListItemButton>
            </ListItem>
          </MenuItemWrapper>
        ))}
      </List>
      <PremiumFeaturePopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
      />
    </Drawer>
  );
};

type MenuItemWrapperProps = {
  menu: typeof sidebarItems[0];
  children: React.ReactNode;
  handleCloseSidebar: () => void;
};

const MenuItemWrapper = ({
  menu,
  children,
  handleCloseSidebar,
}: MenuItemWrapperProps) => {
  // For premium items, we do not wrap in a Link.
  if (menu.premium) {
    return children;
  }
  return (
    <Link
      style={{ textDecoration: "none" }}
      to={menu.route}
      onClick={handleCloseSidebar}
    >
      {children}
    </Link>
  );
};

export default MobileSidebar;