// src/supabase/meterService.ts
import { api } from "../../../supabase/api";

/** Interfaz principal del medidor */
export interface MeterData {
  id: string;
  apartment: string;
  period: string; // Ej: "enero de 2025"
  initial: number | string; // Lectura anterior (cálculo local)
  final: number | string; // Lectura actual (se guarda en BD como meter_reading)
  consumption: number | string;
  created_at: string;
  image_url?: string;
  needsEdit?: boolean;
  readingPeriodRaw?: string; // "YYYY-MM"
}

interface MeterDataExtended extends MeterData {
  readingPeriodRaw?: string;
  building_id: string;
}

export const meterService = {
  async getMeters(buildingId: string, readingPeriod?: string): Promise<MeterData[]> {

    const filterOptions: { select: string; eq?: Record<string, string> } = {
      select: `
        id,
        meter_reading,
        created_at,
        reading_period,
        image_url,
        apartments!inner(apartment_number, building_id)
      `,
    };


    try {
      const data = await api.get("water_meters", filterOptions);

      if (!data || data.length === 0) {
        console.warn("No se encontraron datos en la tabla water_meters.");
        return [];
      }

      const rows = data.map((item: any) => ({
        id: item.id,
        apartment: item.apartments.apartment_number,
        meterReading: parseFloat(item.meter_reading),
        readingPeriod: item.reading_period,
        created_at: item.created_at,
        image_url: item.image_url,
        building_id: item.apartments.building_id,
      }));

      const dict: Record<string, Record<string, any>> = {};
      const aptKey = (apartment: string) => apartment.trim();

      rows.forEach((r) => {
        const key = aptKey(r.apartment);
        if (!dict[key]) dict[key] = {};
        dict[key][r.readingPeriod] = r;
      });


      function getPreviousPeriod(rp: string): string {
        if (!rp && !rp?.includes("-")) return "";
        const [yyyy, mm] = rp.split("-");
        let year = parseInt(yyyy, 10);
        let month = parseInt(mm, 10);
        if (month > 1) {
          month--;
        } else {
          month = 12;
          year--;
        }
        return `${year}-${String(month).padStart(2, "0")}`;
      }

      const result: (MeterDataExtended)[] = rows.map((item) => {
        const apt = aptKey(item.apartment);
        const prevPeriod = getPreviousPeriod(item.readingPeriod);
        const prevRow = dict[apt][prevPeriod] || null;
        const periodStr = formatPeriod(item.readingPeriod);

        if (!prevRow) {
          return {
            id: item.id,
            apartment: item.apartment,
            period: periodStr,
            initial: "",
            final: item.meterReading,
            consumption: "",
            created_at: item.created_at,
            image_url: item.image_url,
            needsEdit: true,
            readingPeriodRaw: item.readingPeriod,
            building_id: item.building_id,
          };
        } else {
          const initialVal = prevRow.meterReading;
          const finalVal = item.meterReading;
          const consumptionVal = finalVal - initialVal;

          return {
            id: item.id,
            apartment: item.apartment,
            period: periodStr,
            initial: initialVal,
            final: finalVal,
            consumption: consumptionVal,
            created_at: item.created_at,
            image_url: item.image_url,
            needsEdit: false,
            readingPeriodRaw: item.readingPeriod,
            building_id: item.building_id,
          };
        }
      });

      return result.filter(item => 
        (!buildingId || item.building_id === buildingId) && 
        (!readingPeriod || item.readingPeriodRaw === readingPeriod)
      );

    } catch (error) {
      console.error("Error en meterService.getMeters:", error);
      throw error;
    }
  },

  async updateMeter(updatedMeter: MeterData) {
    try {
      const payload: Record<string, any> = { meter_reading: updatedMeter.final };
      if (updatedMeter.image_url !== undefined) {
        payload.image_url = updatedMeter.image_url;
      }
      if (updatedMeter.readingPeriodRaw) {
        payload.reading_period = updatedMeter.readingPeriodRaw;
      }


      await api.update("water_meters", updatedMeter.id, payload);
    } catch (error) {
      console.error("Error en meterService.updateMeter:", error);
      throw error;
    }
  },
};

function formatPeriod(rp: string): string {
  const [year, month] = rp.split("-");
  const dateObj = new Date(+year, +month - 1, 1);
  return dateObj.toLocaleString("es-ES", { month: "long", year: "numeric" });
}
