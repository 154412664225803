import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  TablePagination,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { set } from 'date-fns';

interface DataTableProps {
  apartments: any[];
  fileData: any[];
  setFileData: (data: any[]) => void;
  editIndex: number | null;
  setEditIndex: (index: number | null) => void;
  editResidente: string;
  setEditResidente: (value: string) => void;
  editMonto: string;
  setEditMonto: (value: string) => void;
  editDepto: string;
  setEditDepto: (value: string) => void;
  setError: (error: any) => void;
}

export default function MaintenanceFeeDataTable({
  apartments,
  fileData,
  setFileData,
  editIndex,
  setEditIndex,
  editResidente,
  setEditResidente,
  editMonto,
  setEditMonto,
  editDepto,
  setEditDepto,
  setError,
}: DataTableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const handleEditClick = (index: number, residente: string, monto: string, depto: string) => {
    setEditIndex(index);
    setEditMonto(monto);
    setEditResidente(residente);
    setEditDepto(depto);
  };

  const handleSaveChanges = (index: number) => {
    const updatedFileData = [...fileData];
    updatedFileData[index] = {
      ...updatedFileData[index],
      monto: Number(editMonto),
      residente: editResidente,
      depto: editDepto,
    };
    setFileData(updatedFileData);
    setEditIndex(null);
    setEditResidente('');
    setEditMonto('');
    setEditDepto('');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const allValid = fileData.every((row) =>
      apartments
        .map((apartment) => apartment.apartment_number.toUpperCase())
        .includes(row.depto.toUpperCase())
    );

    setError(!allValid);
  }, [fileData, apartments, setError]);

  useEffect(() => {
    const updatedFileData = fileData.map((row) => {
      const matchingDepto = apartments.find(
        (apartment) => apartment.apartment_number.toUpperCase() === row.depto.toUpperCase()
      );

      if (matchingDepto && !row.id) {
        return { ...row, id: matchingDepto.id };
      }

      return row;
    });

    if (JSON.stringify(updatedFileData) !== JSON.stringify(fileData)) {
      setFileData(updatedFileData);
    }
  }, [fileData, apartments, setFileData]);

  const paginatedData = fileData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 5, borderRadius: '16px' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#D9E2FF' }}>
              <TableCell>Depto.</TableCell>
              <TableCell>Residente</TableCell>
              <TableCell>Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => {
              const globalIndex = page * rowsPerPage + index; // Índice original del fileData
              const matchingDepto = apartments.find(
                (apartment) => apartment.apartment_number.toUpperCase() === row.depto.toUpperCase()
              );
              const isValidDepto = Boolean(matchingDepto);

              return (
                <TableRow key={globalIndex}>
                  <TableCell>
                    {editIndex === globalIndex ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          value={editDepto}
                          onChange={(e) => setEditDepto(e.target.value)}
                          variant="outlined"
                          size="small"
                          sx={{ width: '100px', mr: 1 }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        {isValidDepto ? (
                          row.depto.toUpperCase()
                        ) : (
                          <>
                            {row.depto.toUpperCase()}
                            <br />
                            <Typography sx={{ color: 'red' }} variant="body2">
                              El depto. no existe en el edificio. Editalo.
                            </Typography>
                          </>
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === globalIndex ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          value={editResidente}
                          onChange={(e) => setEditResidente(e.target.value)}
                          variant="outlined"
                          size="small"
                          sx={{ width: '100px', mr: 1 }}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {row.residente}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === globalIndex ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          value={editMonto}
                          onChange={(e) => setEditMonto(e.target.value)}
                          variant="outlined"
                          size="small"
                          sx={{ width: '100px', mr: 1 }}
                        />
                        <Button onClick={() => handleSaveChanges(globalIndex)}>Guardar</Button>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        S/. {row.monto}
                        <IconButton
                          size="small"
                          onClick={() => handleEditClick(globalIndex, row.residente, row.monto, row.depto)}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={fileData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </>
  );
}
