// src/features/incident/hooks/useIncident.js
import { useCallback, useState, useEffect } from "react";
import { incidentService } from "../services/incidentService";
import { useAnalytics } from "../../../analytics/hooks/useAnalytics";
import useGlobalStore from "../../../state/store";

export const useIncident = (incidentId = null) => {
  const user = useGlobalStore((state) => state.user);

  const { track } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [incident, setIncident] = useState(null);

  const fetchIncident = async (incidentId: string) => {
    if (!incidentId || !user?.id) {
      setError(
        !incidentId ? "No incident ID provided" : "User not authenticated"
      );
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const data = await incidentService.fetchIncidentById(incidentId, user.id);

      console.log("Fetched incident data:", {
        hasComments: Array.isArray(data.comments),
        commentsCount: data.comments?.length,
        firstComment: data.comments?.[0],
      });

      const transformedIncident = {
        ...data,
        created_at: new Date(data.created_at),
        updated_at: new Date(data.updated_at),
        due_date: data.due_date ? new Date(data.due_date) : null,
        resolution_date: data.resolution_date
          ? new Date(data.resolution_date)
          : null,
        media_urls: Array.isArray(data.media_urls) ? data.media_urls : [],
        metadata: typeof data.metadata === "object" ? data.metadata : {},
        comments: Array.isArray(data.comments) ? data.comments : [],
        status_history: Array.isArray(data.status_history)
          ? data.status_history
          : [],
      };

      console.log(
        "Setting incident state with comments:",
        transformedIncident.comments.length
      );

      setIncident(transformedIncident);
      return transformedIncident;
    } catch (err) {
      const errorMessage = err.message || "Error al cargar la incidencia";
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const createIncident = useCallback(
    async (incidentData) => {
      setLoading(true);
      setError(null);
      try {
        const result = await incidentService.createIncident(
          incidentData,
          user.id
        );

        // Track the event if successful
        if (result?.data?.incident_id) {
          track("incident_created", {
            incident_id: result.data.incident_id,
            category: incidentData.category,
          });
        }

        return result;
      } catch (err) {
        const errorMessage = err.message || "Error al crear la incidencia";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [user?.id, track]
  ); // Updated dependency array

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIncidentStatus = useCallback(
    async (incidentId, newStatus, notes = null) => {
      setLoading(true);
      setError(null);
      try {
        const result = await incidentService.updateIncidentStatus(
          incidentId,
          newStatus,
          user.id,
          notes
        );

        if (result?.data?.incident_id) {
          track("incident_status_updated", {
            incident_id: result.data.incident_id,
            new_status: newStatus,
          });
        }

        return result;
      } catch (err) {
        const errorMessage = err.message || "Error al actualizar el estado";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [user?.id, track]
  ); // Updated dependency array

  const addComment = useCallback(
    async (comment, mediaFiles = [], isInternal = false) => {
      if (!incidentId || !user?.id) {
        setError("Missing incident ID or user not authenticated");
        return;
      }

      const buildingId = incident?.building_id;
      if (!buildingId) {
        setError("Missing building ID from incident data");
        console.error("Incident data:", incident);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const cleanComment = String(comment).trim();
        const cleanMediaFiles = Array.isArray(mediaFiles) ? mediaFiles : [];

        console.log("Adding comment:", {
          incidentId,
          userId: user.id,
          buildingId,
          comment: cleanComment,
          mediaFiles: cleanMediaFiles,
        });

        const result = await incidentService.addComment(
          incidentId,
          user.id,
          cleanComment,
          cleanMediaFiles,
          isInternal,
          buildingId
        );

        if (result.success) {
          await fetchIncident(incidentId);
        }

        return result;
      } catch (err) {
        const errorMessage = err.message || "Error al agregar el comentario";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [incidentId, user?.id, incident, fetchIncident]
  );

  const assignIncidentToWorker = useCallback(
    async (incidentId, workerId) => {
      setLoading(true);
      setError(null);
      try {
        const buildingId = incident?.building_id;
        if (!buildingId) {
          throw new Error("Missing building ID from incident data");
        }

        const result = await incidentService.assignIncidentToWorker(
          incidentId,
          workerId,
          user.id,
          buildingId
        );

        if (result.success) {
          await updateIncidentStatus(incidentId, "in_progress");
          await fetchIncident(incidentId);
        }

        return result;
      } catch (err) {
        const errorMessage = err.message || "Error al asignar la incidencia";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [incident?.building_id, user?.id, updateIncidentStatus]
  ); // Updated dependency array

  return {
    incident,
    createIncident,
    updateIncidentStatus,
    addComment,
    assignIncidentToWorker, // Add this to the return object
    fetchIncident,
    loading,
    error,
  };
};
