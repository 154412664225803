// src/storage/services/supabaseStorage.js
import { supabase } from "../../supabase/client";
import { STORAGE_CONFIG } from "../config/constants";
import { validateFile, validateFiles } from "../utils/validation";
import { generateFileName } from "../utils/fileNaming";

class SupabaseStorageService {
  constructor(bucketName = STORAGE_CONFIG.BUCKET_NAME) {
    this.storage = supabase.storage;
    this.bucketName = bucketName;
  }

  async ensureAuthenticated() {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    if (error || !session) {
      throw new Error("User must be authenticated to access storage");
    }
    return session;
  }

  async uploadFile(file, { path }) {
    try {
      await this.ensureAuthenticated();
      validateFile(file);

      // Generate file path including the provided path
      const filePath = generateFileName(file, path);

      const options = {
        cacheControl: "3600",
        upsert: true,
        contentType: file.type,
      };

      // Upload the file
      const { error: uploadError } = await this.storage
        .from(this.bucketName)
        .upload(filePath, file, options);

      if (uploadError) throw uploadError;

      // Get the signed URL
      const url = await this.getFileUrl(filePath);

      // Return structured response
      return {
        url,
        path: filePath,
        type: file.type,
        size: file.size,
      };
    } catch (error) {
      console.error("Error in uploadFile:", error);
      throw error;
    }
  }

  async uploadMultipleFiles(files, { path }) {
    try {
      await this.ensureAuthenticated();

      if (!files || files.length === 0) return [];

      const filesArray = Array.isArray(files) ? files : Array.from(files);
      validateFiles(filesArray);

      const uploadPromises = filesArray.map((file) => this.uploadFile(file, { path }));

      const results = await Promise.all(uploadPromises);
      return results.filter((result) => result !== null);
    } catch (error) {
      console.error("Error in uploadMultipleFiles:", error);
      throw error;
    }
  }

  async getFileUrl(filePath) {
    try {
      await this.ensureAuthenticated();

      const { data, error } = await this.storage
        .from(this.bucketName)
        .createSignedUrl(filePath, 3155760000);

      if (error) throw error;
      return data.signedUrl;
    } catch (error) {
      console.error("Error getting signed URL:", error);
      throw error;
    }
  }

  async deleteFile(filePath) {
    try {
      await this.ensureAuthenticated();

      const { error } = await this.storage.from(this.bucketName).remove([filePath]);

      if (error) throw error;
    } catch (error) {
      console.error("Error in deleteFile:", error);
      throw error;
    }
  }

  async deleteMultipleFiles(filePaths) {
    try {
      await this.ensureAuthenticated();

      if (!filePaths || filePaths.length === 0) return true;

      const { error } = await this.storage.from(this.bucketName).remove(filePaths);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error("Error in deleteMultipleFiles:", error);
      throw error;
    }
  }

  async moveFile(oldPath, newPath) {
    try {
      await this.ensureAuthenticated();

      const { error } = await this.storage.from(this.bucketName).move(oldPath, newPath);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error("Error in moveFile:", error);
      throw error;
    }
  }

  async copyFile(sourcePath, destinationPath) {
    try {
      await this.ensureAuthenticated();

      const { error } = await this.storage.from(this.bucketName).copy(sourcePath, destinationPath);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error("Error in copyFile:", error);
      throw error;
    }
  }

  async listFiles(prefix = "") {
    try {
      await this.ensureAuthenticated();

      const { data, error } = await this.storage.from(this.bucketName).list(prefix);

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error in listFiles:", error);
      throw error;
    }
  }
}

export const supabaseStorage = new SupabaseStorageService();
