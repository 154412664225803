// src/supabase/api.js

import { supabase } from "./client";

export const api = {
  async get(table, options = {}) {
    let query = supabase.from(table).select(options.select || "*");

    if (options.filter) {
      Object.entries(options.filter).forEach(([key, value]) => {
        query = query.eq(key, value);
      });
    }

    if (options.order) {
      Object.entries(options.order).forEach(([key, direction]) => {
        query = query.order(key, { ascending: direction === "asc" });
      });
    }

    if (options.single) {
      query = query.single();
    }

    const { data, error } = await query;
    if (error) throw error;
    return data;
  },

  async post(table, data) {
    const { data: result, error } = await supabase.from(table).insert(data);
    if (error) throw error;
    return result;
  },

  async update(table, id, data) {
    const { data: result, error } = await supabase
      .from(table)
      .update(data)
      .eq("id", id);
    if (error) throw error;
    return result;
  },

  async delete(table, id) {
    const { error } = await supabase.from(table).delete().eq("id", id);
    if (error) throw error;
  },

  async rpc(functionName, params) {
    const { data, error } = await supabase.rpc(functionName, params);
    if (error) {
      console.error(`RPC error in function ${functionName}:`, error);
      throw error;
    }
    return data;
  },
};
