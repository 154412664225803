import Grid2 from "@mui/material/Grid2";
import createNumberArray from "../../../shared/utils/createNumberArray";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box/Box";

export const VisitCardSkeleton = () => (
  <Card sx={{ mb: 2, borderRadius: 4 }}>
    <CardContent sx={{ p: 2 }}>
      <Skeleton width={120} height={24} variant="text" />
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Skeleton width={160} height={18} variant="text" />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Skeleton width={150} height={18} variant="text" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Skeleton width={80} height={32} variant="rounded" />

        <Skeleton width={40} height={40} variant="rounded" />
      </Box>
    </CardContent>
  </Card>
);

export const VisitListSkeleton = () => (
  <Grid2 container spacing={2} direction="column">
    {createNumberArray(5).map((num) => (
      <Grid2 key={num}>
        <VisitCardSkeleton />
      </Grid2>
    ))}
  </Grid2>
);
