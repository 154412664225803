// src/config/sidebarConfig.tsx
import React from "react";
import {
  CalendarToday,
  Chat,
  LocalLaundryService,
  LocalShipping,
  MonetizationOn,
  OpacityOutlined,
  People,
  Room,
  Warning,
} from "@mui/icons-material";
import { ROUTES } from "./routes"; // Ensure the routes file has the needed keys

export type SidebarItem = {
  text: string;
  icon: React.ReactElement;
  route: string;
  premium: boolean;
};

export const sidebarItems: SidebarItem[] = [
  { text: "Visitas", icon: <People />, route: ROUTES.VISITS, premium: false },
  {
    text: "Reservas",
    icon: <CalendarToday />,
    route: ROUTES.RESERVATIONS, // Make sure ROUTES.RESERVATIONS exists
    premium: true,
  },
  {
    text: "Lavandería",
    icon: <LocalLaundryService />,
    route: ROUTES.LAUNDRY, // Make sure ROUTES.LAUNDRY exists
    premium: true,
  },
  {
    text: "Áreas comunes",
    icon: <Room />,
    route: ROUTES.COMMON_AREAS, // Make sure ROUTES.COMMON_AREAS exists
    premium: true,
  },
  {
    text: "Delivery",
    icon: <LocalShipping />,
    route: ROUTES.DELIVERY, // Make sure ROUTES.DELIVERY exists
    premium: true,
  },
  {
    text: "Residentes",
    icon: <People />,
    route: ROUTES.RESIDENTS, // Make sure ROUTES.RESIDENTS exists
    premium: true,
  },
  { text: "Chat", icon: <Chat />, route: ROUTES.CHAT, premium: true },
  {
    text: "Incidencias",
    icon: <Warning />,
    route: ROUTES.INCIDENTS, // Make sure ROUTES.INCIDENTS exists
    premium: false,
  },
  {
    text: "Contadores",
    icon: <OpacityOutlined />,
    route: ROUTES.METERS, // Make sure ROUTES.METERS exists
    premium: false,
  },
  {
    text: "Contabilidad",
    icon: <MonetizationOn />,
    route: ROUTES.MAINTENANCE_FEE, // Make sure ROUTES.MAINTENANCE_FEE exists
    premium: false,
  },
];