// src/config/routes.ts

type RouteParams = {
  inviteToken?: string;
  visitId?: string;
  buildingId?: string;
  id?: string;
};

export const ROUTES = {
  // Authentication routes
  LOGIN: "/login",
  // REGISTER: '/register',
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: '/reset-password',

  // Dashboard route
  DASHBOARD: "/visits",

  // VISITS: '/dashboard/visits',
  // RESERVATIONS: '/dashboard/reservations',
  // LAUNDRY: "/dashboard/laundry",
  // COMMON_AREAS: '/dashboard/common-areas',
  // DELIVERY: '/dashboard/delivery',
  // RESIDENTS: '/dashboard/residents',
  // CHAT: '/dashboard/chat',
  // FINES: '/dashboard/fines',
  // METERS: '/dashboard/meters',

  // Visit routes
  VISITS: "/visits",
  VISIT_DETAIL: "/visits/:visitId",
  // VISIT_DETAIL: '/dashboard/visit/:visitId',
  MANUAL_VISIT_REGISTRATION: "/visits/manual-visit-registration",

  // Incident routes
  INCIDENTS: "/incidents",
  INCIDENT_NEW: "/incidents/new",
  INCIDENT_DETAIL: "/incidents/:id",

  // Meters routes
  METERS: "/meters",

  MAINTENANCE_FEE: "/maintenance-fee",
  MAINTENANCE_FEE_DETAIL: "/maintenance-fee/:id/detail",
  MAINTENANCE_FEE_IMPORT_STEP_1: "/maintenance-fee/import/",
  MAINTENANCE_FEE_IMPORT_STEP_2: "/maintenance-fee/import/2",
  MAINTENANCE_FEE_CONFIG: "/maintenance-fee/config",

  // External links
  TERMS: "https://appdomus.io/terminos-y-condiciones",
  PRIVACY: "https://appdomus.io/privacidad",
};

// Tipo que representa las rutas con parámetros
export type RoutePaths = {
  [key in keyof typeof ROUTES]: string;
};

export type RouteParamsType = RouteParams;
