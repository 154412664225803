import React, { MouseEvent } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert as MoreVertIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { MeterRow } from "./MeterTable";

interface MeterActionsProps {
  meter: MeterRow;
  onViewDetails: (meter: MeterRow) => void;
}

const MeterActions: React.FC<MeterActionsProps> = ({ meter, onViewDetails }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    onViewDetails(meter);
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        aria-label="acciones"
        aria-controls={open ? "meter-actions-menu" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="meter-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDetails}>
          <VisibilityIcon sx={{ mr: 1 }} />
          Ver Detalle
        </MenuItem>
      </Menu>
    </>
  );
};

export default MeterActions;
