// src/features/maintenanceFee/components/config/AddAccountModal.jsx

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";

// Hardcoded bank + type options
const BANK_OPTIONS = [
  { name: "Interbank", payment_method: "bank_transfer" },
  { name: "BCP", payment_method: "bank_transfer" },
  { name: "Scotiabank", payment_method: "bank_transfer" },
  { name: "BBVA", payment_method: "bank_transfer" },
];
const ACCOUNT_TYPES = ["Ahorros", "Corriente"];

const AddAccountModal = ({ open, onClose, onAddAccount }) => {
  const [bank, setBank] = useState("");
  const [type, setType] = useState("");
  const [owner, setOwner] = useState("");
  const [cci, setCci] = useState("");
  const [account, setAccount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleSubmit = () => {
    if (!bank || !type || !owner || !cci || !account || !paymentMethod) {
      alert("Por favor completa todos los campos.");
      return;
    }

    // Build account object
    const newAccount = {
      bank,
      type,
      owner,
      cci,
      account,
      paymentMethod,
    };

    onAddAccount(newAccount);

    // Clear & close
    setBank("");
    setType("");
    setOwner("");
    setCci("");
    setAccount("");
    setPaymentMethod("");
    onClose();
  };

  const handleBankChange = (e) => {
    const selectedBank = BANK_OPTIONS.find((b) => b.name === e);
    setBank(selectedBank.name);
    setPaymentMethod(selectedBank.payment_method);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Agregar Cuenta Bancaria</DialogTitle>
      <DialogContent>
        {/* Banco */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Banco</InputLabel>
          <Select value={bank} label="Banco" onChange={(e) => handleBankChange(e.target.value)}>
            {BANK_OPTIONS.map((b) => (
              <MenuItem key={b.name} value={b.name}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Tipo de cuenta */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Tipo de cuenta</InputLabel>
          <Select value={type} label="Tipo de cuenta" onChange={(e) => setType(e.target.value)}>
            {ACCOUNT_TYPES.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Titular */}
        <TextField
          label="Titular"
          fullWidth
          sx={{ mt: 2 }}
          value={owner}
          onChange={(e) => setOwner(e.target.value)}
        />

        {/* Cód. Interbancario */}
        <TextField
          label="Cód. Interbancario"
          fullWidth
          sx={{ mt: 2 }}
          value={cci}
          onChange={(e) => setCci(e.target.value.replace(/\D/g, ""))} // keep only digits
        />

        {/* Número de cuenta */}
        <TextField
          label="Número de Cuenta"
          fullWidth
          sx={{ mt: 2 }}
          value={account}
          onChange={(e) => setAccount(e.target.value.replace(/\D/g, ""))} // keep only digits
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Añadir Cuenta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;
